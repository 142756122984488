<template>
  <div v-if="info">
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ getData.title }}
        </h2>
      </div>
      <div class="section__content">
        <div class="row px-5 justify-content-center align-items-stretch">
          <template v-for="(item, index) in getData.print_modes" :key="index">
            <div class="col-md-3">
              <div class="print-mode js-print-mode">
                <div class="print-mode__img">
                  <img :src="item.image" alt="" />
                  <div class="new js-new-box" v-if="item.new_icon">
                    <div v-if="item.new" v-html="item.new"></div>

                    <div v-html="item.new_icon"></div>
                  </div>
                  <div class="characteristics" v-if="item.characteristics" v-html="item.characteristics"></div>
                  <div class="speed" v-html="item.speed"></div>
                </div>
                <div class="print-mode__text">
                  <h3>
                    {{ item.name }}
                  </h3>
                  <div class="print-mode__colors">
                    <div class="wrap-colors" v-html="item.image_colors"></div>
                    <div v-html="item.notes"></div>
                  </div>
                  <div class="col-12 mt-3 text-center" v-if="item.video_cta && !shareableVersion">
                    <Button
                      href="javascript:void(0)"
                      data-gtm-section="Technology"
                      data-gtm-subsection="Print Modes"
                      data-gtm-label="Watch Print Modes Video"
                      data-target="#print-modes-video"
                      data-toggle="modal"
                      @click="clickTracked($event)">
                      {{ item.video_label }}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="index != Object.keys(getData.print_modes).length" class="border--deco"></div>
          </template>

          <div class="col-12 text-center">
            <div class="print-conventions js-print-conventions">
              <template v-for="(item, index) in getData.conventions" :key="index">
                <div v-html="item.item"></div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ShareButton
      v-if="!shareableVersion && getData.shareable_pdf"
      :hash="hashify(`${$t('main-nav.technology')} - ${$t('technology.submenu.print-modes')} ${printerName}`)"
      :shareLabel="`${$t('main-nav.technology')} - ${$t('technology.submenu.print-modes')} ${printerName}`"
      :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
      @share-this="shareThis" />

    <template v-for="(item, index) in getData.print_modes" :key="index">
      <Modal v-if="item.video_cta" idModal="print-modes-video">
        <Media data-gtm-section="Technology" data-gtm-label="Play Print Modes Video" :poster="item.poster" :src="item.video_cta" />
      </Modal>
    </template>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import Modal from '@/components/modal/Modal.vue';
import ShareButton from '@/components/ui/ShareButton.vue';
import Button from '@/components/ui/Button.vue';
import Media from '@/components/ui/Media.vue';

export default {
  name: 'PrintModes',
  mixins: [filters, defaultMixins],
  components: {
    Button,
    ShareButton,
    Media,
    Modal
  },
  data() {
    return {
      info: null
    };
  },
  created() {
    axios
      .get(
        `${endpoints.CMS}items/technology_print_modes`,
        {
          params: {
            fields: '*,translations.*,printer.printers_id'
          }
        },
        {
          'Cache-Control': 'no-cache',
          Expires: '-1'
        }
      )
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/views/technology/printModes.scss';
</style>
