import { createRouter, createWebHistory } from 'vue-router';

// Main Sections
import CustomerStories from '@/views/CustomerStories.vue';
import Intro from '@/views/Intro.vue';
import Library from '@/views/Library.vue';
import PartnerSolutions from '@/views/PartnerSolutions.vue';
import ServiceSupport from '@/views/ServiceSupport.vue';
import Package from '@/views/Package.vue';
import Technology from '@/views/Technology.vue';
import Upgradability from '@/views/Upgradability.vue';
import VirtualTour from '@/views/VirtualTour.vue';

// Sidebar Sections
import About from '@/views/About.vue';
import Feedback from '@/views/Feedback.vue';
import NextSteps from '@/views/NextSteps.vue';
import Profile from '@/views/Profile.vue';
import Team from '@/views/Team.vue';

// Technology Sub Sections
import TechnicalSpecs from '@/views/technology/TechnicalSpecs.vue';
import HPTIJ from '@/views/technology/HPTIJ.vue';
import PrintModes from '@/views/technology/PrintModes.vue';
import BrilliantInk from '@/views/technology/BrilliantInk.vue';
import A50Ink from '@/views/technology/A50Ink.vue';
import PaperPath from '@/views/technology/PaperPath.vue';

// Partner Solutions Sub Sections
import StandardOptions from '@/views/partner-solutions/StandardOptions.vue';
import CustomFinishing from '@/views/partner-solutions/CustomFinishing.vue';
import Workflow from '@/views/partner-solutions/Workflow.vue';

// Upgradability Sub Sections
import ConfigurationOptions from '@/views/upgradability/ConfigurationOptions.vue';
import TowerOfProduction from '@/views/upgradability/TowerOfProduction.vue';
import UpgradabilityPath from '@/views/upgradability/UpgradabilityPath.vue';
import UpgradabilityVideo from '@/views/upgradability/UpgradabilityVideo.vue';
import InvestmentScenario from '@/views/upgradability/InvestmentScenario.vue';

// Technology Routes
const technologyRoutes = [
  {
    path: '',
    name: 'Technical Specs',
    component: TechnicalSpecs
  },
  {
    path: 'technical-specs',
    component: TechnicalSpecs
  },
  {
    path: 'hp-tij',
    component: HPTIJ
  },
  {
    path: 'print-modes',
    component: PrintModes
  },
  {
    path: 'brilliant-ink',
    component: BrilliantInk
  },
  {
    path: 'ink',
    component: A50Ink
  },
  {
    path: 'paper-path',
    component: PaperPath
  }
];

// Partner Solutions Routes
const partnerSolutionsRoutes = [
  {
    path: '',
    name: 'Standard Options',
    component: StandardOptions
  },
  {
    path: 'standard-options',
    component: StandardOptions
  },
  {
    path: 'custom-finishing',
    component: CustomFinishing
  },
  {
    path: 'workflow',
    component: Workflow
  }
];

// Upgradability
const upgradabilityRoutes = [
  {
    path: '',
    name: 'Upgradability Path',
    component: UpgradabilityPath
  },
  {
    path: 'configuration-options',
    component: ConfigurationOptions
  },
  {
    path: 'upgradability-path',
    component: UpgradabilityPath
  },
  {
    path: 'upgradability-video',
    component: UpgradabilityVideo
  },
  {
    path: 'scalability-video',
    component: UpgradabilityVideo
  },
  {
    path: 'investment-scenario',
    component: InvestmentScenario
  },
  {
    path: 'tower-of-production',
    component: TowerOfProduction
  }
];

const mainRoutes = [
  // Main Nav Routes
  {
    path: '/',
    name: 'Home',
    component: Intro
  },
  {
    path: '/intro',
    name: 'Intro',
    component: Intro
  },
  {
    path: '/virtual-tour',
    name: 'VirtualTour',
    component: VirtualTour
  },
  {
    path: '/virtual-tour/:id',
    name: 'VirtualTourHotspot',
    component: VirtualTour
  },
  {
    path: '/technology',
    name: 'Technology',
    component: Technology,
    children: [...technologyRoutes]
  },
  {
    path: '/partner-solutions',
    name: 'PartnerSolutions',
    component: PartnerSolutions,
    children: [...partnerSolutionsRoutes]
  },
  {
    path: '/upgradability',
    name: 'Upgradability',
    component: Upgradability,
    children: [...upgradabilityRoutes]
  },
  {
    path: '/scalability',
    name: 'Scalability',
    component: Upgradability,
    children: [...upgradabilityRoutes]
  },
  {
    path: '/service-support',
    name: 'ServiceSupport',
    component: ServiceSupport
  },
  {
    path: '/package',
    name: 'Package',
    component: Package
  },
  {
    path: '/customer-stories',
    name: 'CustomerStories',
    component: CustomerStories
  },
  {
    path: '/library',
    name: 'Library',
    component: Library
  }
];

const sidebarRoutes = [
  // Sidebar Nav Routes
  {
    path: '/about-us',
    name: 'AboutUs',
    component: About
  },
  {
    path: '/team',
    name: 'Team',
    component: Team
  },
  {
    path: '/next-steps',
    name: 'NextSteps',
    component: NextSteps
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback
  }
];

const router = createRouter({
  base: '/',
  history: createWebHistory(),
  routes: [...mainRoutes, ...sidebarRoutes]
});

export default router;
