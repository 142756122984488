<template>
  <div class="section__content--upgrade investment-scenario" v-if="info">
    <!-- eslint-disable max-len -->
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ $t('upgradability.submenu.investment-scenario') }}
        </h2>
        <span v-if="!shareableVersion" class="reset-timeline" @click="resetTimeline">
          {{ $t('upgradability.reset-timeline') }}
        </span>
      </div>
      <div class="section__content">
        <div class="row align-items-start">
          <div class="col-md-10">
            <div class="cost-timeline">
              <div class="row align-items-end">
                <div class="col-sm-3">
                  <div class="single-year single-year--cost">
                    <div
                      class="single-year__detail single-year__detail--cost"
                      ref="yearDetail1top">
                      <div class="featured" v-html="getData.year_1_top_comments"></div>
                      <div class="header">
                        <h4>
                          {{ getData.year_1_top_printer }}
                        </h4>
                      </div>
                      <div class="content">
                        <div
                          v-for="(item, index) in getData.year_1_top_features"
                          :key="index"
                          v-html="item.feature"></div>
                      </div>
                    </div>
                    <div class="single-year__intro single-year__intro--cost">
                      <h5 class="year">
                        {{ getData.year_1_top_year }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div
                    class="single-year single-year--cost">
                    <div
                      class="single-year__detail single-year__detail--cost"
                      ref="yearDetail2top">
                      <div class="featured" v-html="getData.year_2_top_comments"></div>
                      <div class="header">
                        <h4>
                          {{ getData.year_2_top_printer }}
                        </h4>
                      </div>
                      <div class="content">
                        <div
                          v-for="(item, index) in getData.year_2_top_features"
                          :key="index"
                          v-html="item.feature"></div>
                      </div>
                    </div>
                    <div
                      class="single-year__intro single-year__intro--cost"
                      ref="yearIntro2" >
                      <div
                        class="single-year__pin ra-gtm-event"
                        data-gtm-section="Upgradability"
                        data-gtm-subsection="Investment Scenario"
                        :data-gtm-label="`Upgrade Year ${getData.year_2_top_year}`"
                        ref="pin2"
                        @click="upgradeTimeline('2', $event.target); clickTracked($event)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21"
                          viewBox="0 0 21 21">
                          <path
                            d="M10.5,21,21,10.5,10.5,0,8.645,1.855,15.97,9.211H0v2.578H15.97L8.645,19.145Z"
                            fill="#0072ad" />
                        </svg>
                      </div>
                      <h5>
                        {{ getData.year_2_top_year }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div
                    class="single-year single-year--cost">
                    <div
                      class="single-year__detail single-year__detail--cost"
                      ref="yearDetail3top">
                      <div class="featured" v-html="getData.year_3_top_comments"></div>
                      <div class="header">
                        <h4>
                          {{ getData.year_3_top_printer }}
                        </h4>
                      </div>
                      <div class="content">
                        <div
                          v-for="(item, index) in getData.year_3_top_features"
                          :key="index"
                          v-html="item.feature"></div>
                      </div>
                    </div>
                    <div
                      class="single-year__intro single-year__intro--cost"
                      ref="yearIntro3" >
                      <div
                        class="single-year__pin ra-gtm-event"
                        data-gtm-section="Upgradability"
                        data-gtm-subsection="Investment Scenario"
                        :data-gtm-label="`Upgrade Year ${getData.year_3_top_year}`"
                        ref="pin3"
                        @click="upgradeTimeline('3', $event.target); clickTracked($event)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21"
                          viewBox="0 0 21 21">
                          <path
                            d="M10.5,21,21,10.5,10.5,0,8.645,1.855,15.97,9.211H0v2.578H15.97L8.645,19.145Z"
                            fill="#0072ad" />
                        </svg>
                      </div>
                      <h5>
                        {{ getData.year_3_top_year }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div
                    class="single-year single-year--cost">
                    <div
                      class="single-year__detail single-year__detail--cost"
                      ref="yearDetail4top">
                      <div class="featured" v-html="getData.year_4_top_comments"></div>
                      <div class="header">
                        <h4>
                          {{ getData.year_4_top_printer }}
                        </h4>
                      </div>
                      <div class="content">
                        <div
                          v-for="(item, index) in getData.year_4_top_features"
                          :key="index"
                          v-html="item.feature"></div>
                      </div>
                    </div>
                    <div
                      class="single-year__intro single-year__intro--cost"
                      ref="yearIntro4" >
                      <div
                        class="single-year__pin ra-gtm-event"
                        data-gtm-section="Upgradability"
                        data-gtm-subsection="Investment Scenario"
                        :data-gtm-label="`Upgrade Year ${getData.year_4_top_year}`"
                        ref="pin4"
                        @click="upgradeTimeline('4', $event.target); clickTracked($event)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21"
                          viewBox="0 0 21 21">
                          <path
                            d="M10.5,21,21,10.5,10.5,0,8.645,1.855,15.97,9.211H0v2.578H15.97L8.645,19.145Z"
                            fill="#0072ad" />
                        </svg>
                      </div>
                      <h5>
                        {{ getData.year_4_top_year }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col-sm-3">
                  <div
                    class="single-year single-year--cost single-year--cost--reverse">
                    <div
                      class="single-year__detail single-year__detail--cost" ref="yearDetail1bot">
                      <div class="featured" v-html="getData.year_1_bottom_comments"></div>
                      <div class="header">
                        <h4>
                          {{ getData.year_1_bottom_printer }}
                        </h4>
                      </div>
                      <div class="content">
                        <div
                          v-for="(item, index) in getData.year_1_bottom_features"
                          :key="index"
                          v-html="item.feature"></div>
                      </div>
                    </div>
                    <div class="single-year__intro single-year__intro--cost">
                      <h5 class="year">
                        {{ getData.year_1_bottom_year }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div
                    class="single-year single-year--cost single-year--cost--reverse">
                    <div
                      class="single-year__detail single-year__detail--cost" ref="yearDetail2bot">
                      <div class="upgrade__forklift" ref="forklift1">
                        <img src="images/upgradability-img/forklift.png" alt="">
                      </div>
                      <div class="featured" v-html="getData.year_2_bottom_comments"></div>
                      <div class="header">
                        <h4>
                          {{ getData.year_2_bottom_printer }}
                        </h4>
                      </div>
                      <div class="content">
                        <div
                          v-for="(item, index) in getData.year_2_bottom_features"
                          :key="index"
                          v-html="item.feature"></div>
                      </div>
                    </div>
                    <div class="single-year__intro single-year__intro--cost">
                      <h5 class="year">
                        {{ getData.year_2_bottom_year }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div
                    class="single-year single-year--cost single-year--cost--reverse">
                    <div
                      class="single-year__detail single-year__detail--cost" ref="yearDetail3bot">
                      <div class="upgrade__forklift" ref="forklift2">
                        <img src="images/upgradability-img/forklift.png" alt="">
                      </div>
                      <div class="featured" v-html="getData.year_3_bottom_comments"></div>
                      <div class="header">
                        <h4>
                          {{ getData.year_3_bottom_printer }}
                        </h4>
                      </div>
                      <div class="content">
                        <div
                          v-for="(item, index) in getData.year_3_bottom_features"
                          :key="index"
                          v-html="item.feature"></div>
                      </div>
                    </div>
                    <div class="single-year__intro single-year__intro--cost">
                      <h5 class="year">
                        {{ getData.year_3_bottom_year }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="timeline__line timeline-line--cost">
              <div class="line-progress" ref="progressLine"></div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="row justify-content-center">
              <div class="col-12">
                <div :class="['company-cost', 'company-cost--first',
                {'order': $i18n.locale !== 'en'}]">
                  <div class="logo">
                    <svg class="hp-logo" viewBox="0 0 432 432">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M216,432c-2.15,0-4.29-0.04-6.42-0.1l44.14-121.32h60.75c10.66,0,22.37-8.2,26.01-18.21l47.89-131.55 c7.84-21.54-4.5-39.16-27.42-39.16h-84.28l-70.71,194.32h-0.03l-40.09,110.15C70.74,403.52,0,318.03,0,216 C0,116.92,66.72,33.42,157.68,7.97l-41.37,113.69h-0.01L47.54,310.58l45.78, 0l58.48-160.53h34.4l-58.48,160.53l45.76,0.01 l54.5-149.77c7.84-21.54-4.5-39.16-27.39-39.16h-38.48L206.29,0.23C209.51,0.08,212.75,0,216,0c119.29,0,216,96.71,216,216 C432,335.29,335.29,432,216,432z M346.69,149.94H312.3L264.13,282.1h34.4L346.69,149.94z" />
                    </svg>
                  </div>
                  <span
                    :class="['price',
                    {'small': $i18n.locale === 'de'},
                    {'x-small': $i18n.locale === 'fr'}]">
                    {{ priceHp }}
                  </span>
                  <p>
                    {{ $t('upgradability.investment-scenario.aprox') }}
                  </p>
                </div>
              </div>
              <div class="col-12 py-4 text-center">
                <div class="company-cost__spacer">
                  <span>
                    VS.
                  </span>
                </div>
              </div>
              <div class="col-12">
                <div :class="['company-cost',
                {'order': $i18n.locale !== 'en'}]">
                  <div class="logo">
                    <p>
                      {{ $t('upgradability.investment-scenario.alt-solution') }}
                    </p>
                  </div>
                  <span
                    :class="['price',
                    {'small': $i18n.locale === 'de'},
                    {'x-small': $i18n.locale === 'fr'}]">
                    {{ priceAlt }}
                  </span>
                  <p>
                    {{ $t('upgradability.investment-scenario.aprox') }}
                  </p>
                </div>
                <small class="cost-disclaimer">
                  <span>*</span> {{ $t('upgradability.investment-scenario.note') }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ShareButton
      v-if="!shareableVersion && getData.shareable_pdf"
      :hash="hashify(`${$t('main-nav.upgradability')} - ${$t('upgradability.submenu.investment-scenario')} ${printerName}`)"
      :shareLabel="`${$t('main-nav.upgradability')} - ${$t('upgradability.submenu.investment-scenario')} ${printerName}`"
      :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
      @share-this="shareThis" />

  </div>
</template>

<script>
import axios from 'axios';
import { gsap } from 'gsap';

import * as endpoints from '@/common/endpoints';
import { bounceIn, bounceOut, moveForklift } from '@/common/animations';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'InvestmentScenario',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton
  },
  data() {
    return {
      cmsURL: endpoints.CMS,
      firstRender: true,
      info: null,
      priceHp: '1.8M',
      priceAlt: '1.8M'
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/investment_scenario`, {
      params: {
        fields: '*,translations.*,printer.printers_id'
      }
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1'
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.hideMainTabs();
  },
  updated() {
    if (this.firstRender) {
      this.initTimeline();
    }
  },
  methods: {
    initTimeline() {
      bounceIn([this.$refs.yearDetail1top, this.$refs.yearDetail1bot]);
      bounceIn(this.$refs.pin2);

      if (this.shareableVersion) {
        const shareableForklifts = document.querySelectorAll('.upgrade__forklift');
        const shareableSingleYears = document.querySelectorAll('.single-year');
        const shareableSingleYearPin = document.querySelectorAll('.single-year__pin');

        shareableForklifts.forEach((element) => { element.classList.add('hide'); });
        shareableSingleYearPin.forEach((element) => { element.classList.add('hide'); });
        shareableSingleYears.forEach((element) => { element.classList.add('active'); });

        const shareableitems = this.$refs;
        gsap.timeline().to(shareableitems.progressLine, {
          width: '100%',
          duration: 0.3,
          ease: 'linear'
        });

        this.priceHp = this.getData.year_4_top_price;
        this.priceAlt = this.getData.year_3_bottom_price;
      } else {
        // Reset money Values
        this.priceHp = this.getData.year_1_top_price;
        this.priceAlt = this.getData.year_1_bottom_price;
      }
    },
    resetTimeline() {
      bounceOut([
        this.$refs.yearDetail2top,
        this.$refs.yearDetail2bot,
        this.$refs.pin2,
        this.$refs.yearDetail3top,
        this.$refs.yearDetail3bot,
        this.$refs.pin3,
        this.$refs.yearDetail4top,
        this.$refs.pin4
      ]);

      this.$refs.yearIntro2.parentNode.classList.remove('active');
      this.$refs.yearIntro3.parentNode.classList.remove('active');
      this.$refs.yearIntro4.parentNode.classList.remove('active');

      gsap.timeline()
        .to(this.$refs.progressLine, {
          width: '6%',
          duration: 0.3,
          ease: 'linear'
        })
        .set([this.$refs.forklift1, this.$refs.forklift2], {
          x: 0
        })
        .then(() => this.initTimeline());

      // Reset money Values
      this.priceHp = this.getData.year_1_top_price;
      this.priceAlt = this.getData.year_1_bottom_price;
    },
    upgradeTimeline(year, clickedPin) {
      const items = this.$refs;
      this.firstRender = false;

      bounceOut(clickedPin)
        .add(bounceIn(items[`yearDetail${year}top`]), '-=0.3')
        .call(() => items[`yearIntro${year}`].parentNode.classList.add('active'))
        .add(() => {
          switch (year) {
            case '2':
              gsap.timeline()
                .add(bounceIn(items.pin3))
                .to(items.progressLine, {
                  width: '36%',
                  duration: 0.3,
                  ease: 'linear'
                }, '<')
                .add(moveForklift(items[`yearDetail${year}bot`], items.forklift1, '-50vw'), '<');

              // Update money Values
              this.priceHp = this.getData.year_2_top_price;
              this.priceAlt = this.getData.year_2_bottom_price;
              break;

            case '3':
              gsap.timeline()
                .add(bounceIn(items.pin4))
                .to(items.progressLine, {
                  width: '64%',
                  duration: 0.3,
                  ease: 'linear'
                }, '<')
                .add(moveForklift(items[`yearDetail${year}bot`], items.forklift2, '-75vw'), '<');

              // Update money Values
              this.priceHp = this.getData.year_3_top_price;
              this.priceAlt = this.getData.year_3_bottom_price;
              break;

            case '4':
              gsap.timeline()
                .to(items.progressLine, {
                  width: '100%',
                  duration: 0.3,
                  ease: 'linear'
                });

              // Update money Values
              this.priceHp = this.getData.year_4_top_price;
              break;
            default:
              break;
          }
        }, '<');
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/upgradability/base.scss';
  @import '@/scss/views/upgradability/investmentScenario.scss';
</style>
