<template>
  <div class="main-card" v-if="data.content">
    <!-- Partner Solutions Content -->
    <template v-if="cardType === 'solution'">
      <div
        class="main-card__img"
        :style="{'background-image': `url(${cmsURL}assets/${data.image})`}">
        <div class="main-card__check">
          <input
            type="checkbox"
            class="js-share-button"
            :data-hash="hashify(normalizedLabel)"
            @change="$emit('share-card', {
              data: {
                shareLabel: normalizedLabel,
                shareLink: shareableLink,
                hash: hashify(normalizedLabel)
              },
              checked: $event.target.checked
            })">
        </div>
      </div>

      <div class="main-card__text">
        <h3 class="title title--small">
          {{ data.content.name }}
        </h3>

        <div
          :class="['text', {'text--simple': dataSubsection === 'Standard Options'}]"
          v-html="data.content.product">
        </div>

        <a
          v-if="data.website"
          class="link link--first data-link ra-gtm-event"
          target="_blank"
          :href="data.website"
          :data-gtm-section="dataSection"
          :data-gtm-subsection="dataSubsection"
          :data-gtm-label="`${data.identifier} - Visit Website`"
          @click="clickTracked($event)">
          {{ $t('buttons.website') }}
        </a>

        <a
          v-if="data.brochure"
          class="link ra-gtm-event"
          data-toggle="modal"
          :data-target="targetModal"
          :data-gtm-section="dataSection"
          :data-gtm-subsection="dataSubsection"
          :data-gtm-label="`${data.identifier} - Download DataSheet`"
          @click="$emit('show-modal', idCard); clickTracked($event)" >
          {{ $t('buttons.data-sheet') }}
        </a>

        <a
          v-if="data.additional_link"
          class="link ra-gtm-event"
          :data-gtm-section="dataSection"
          :data-gtm-subsection="dataSubsection"
          :data-gtm-label="`${data.identifier} - ${data.additional_link_label}`"
          :href="data.additional_link"
          @click="clickTracked($event)" >
          {{ data.additional_link_label }}
        </a>
      </div>
    </template>

    <!-- Library Content -->
    <template v-else-if="cardType === 'library'">
      <div
        class="main-card__img"
        :style="{'background-image': `url(${cmsURL}assets/${data.content.image}?fit=cover&width=500)`}">
        <div v-if="dataSection" class="main-card__check">
          <template v-if="dataSection === 'Library'">
           <template v-if="data.content.asset">
              <input
                type="checkbox"
                class="js-share-button"
                :data-hash="hashify(normalizedLabel)"
                @change="$emit('share-card', {
                  data: {
                    shareLabel: normalizedLabel,
                    shareLink: shareableLink,
                    hash: hashify(normalizedLabel)
                  },
                  checked: $event.target.checked
                })">
            </template>
            <template v-else>
              <input
                type="checkbox"
                class="js-share-button"
                :data-hash="hashify(normalizedLabel)"
                @change="$emit('share-card', {
                  data: {
                    shareLabel: normalizedLabel,
                    shareLink: shareableLink,
                    hash: hashify(normalizedLabel)
                  },
                  checked: $event.target.checked
                })">
            </template>
          </template>
          <template v-else>
            <input
              v-if="data.content"
              type="checkbox"
              class="js-share-button"
              :data-hash="hashify(normalizedLabel)"
              @change="$emit('share-card', {
                data: {
                  shareLabel: normalizedLabel,
                  shareLink: shareableLink,
                  hash: hashify(normalizedLabel)
                },
                checked: $event.target.checked
              })">
          </template>
        </div>
      </div>

      <div class="main-card__text">
        <span v-if="data.filters" class="category">
          <template v-for="(industry, index) in data.filters.industry" :key="index">
            {{ industry.name }}<span v-if="index != data.filters.industry.length - 1">, </span>
          </template>
        </span>

        <h3 class="title title--small" v-if="data.content">
          <template v-if="dataSection === 'Library'">
            {{ data.content.name }}
          </template>
          <template v-else>
            {{ data.content.title }}
          </template>
        </h3>

        <Button
          v-if="targetModal"
          :class="{'small': $i18n.locale === 'fr'}"
          data-toggle="modal"
          :data-target="targetModal"
          :data-gtm-section="dataSection"
          :data-gtm-subsection="dataSubsection"
          :data-gtm-label="`${data.identifier} - Open Detail`"
          @click="$emit('show-modal', idCard); clickTracked($event)" >
          <template v-if="buttonText">
            {{ buttonText }}
          </template>
          <template v-else>
            {{ $t('buttons.learn-more') }}
          </template>
        </Button>

        <Button
          v-else
          :href="data.content.asset"
          target="_blank"
          :data-target="targetModal"
          :data-gtm-section="dataSection"
          :data-gtm-subsection="dataSubsection"
          :data-gtm-label="`${data.identifier} - Click Link`"
          @click="clickTracked($event)">
          <template v-if="buttonText">
            {{ buttonText }}
          </template>
          <template v-else>
            {{ $t('buttons.learn-more') }}
          </template>
        </Button>
      </div>
    </template>

    <!-- Testimonials Content -->
    <template v-else>
      <div
        class="main-card__img"
        :style="{'background-image': `url(${cmsURL}assets/${data.image}?fit=cover&width=500)`}">
        <div v-if="dataSection" class="main-card__check">
          <template v-if="dataSection === 'Library'">
           <template v-if="data.asset">
              <input
                type="checkbox"
                class="js-share-button"
                :data-hash="hashify(normalizedLabel)"
                @change="$emit('share-card', {
                  data: {
                    shareLabel: normalizedLabel,
                    shareLink: shareableLink,
                    hash: hashify(normalizedLabel)
                  },
                  checked: $event.target.checked
                })">
            </template>
            <template v-else>
              <input
                type="checkbox"
                class="js-share-button"
                :data-hash="hashify(normalizedLabel)"
                @change="$emit('share-card', {
                  data: {
                    shareLabel: normalizedLabel,
                    shareLink: shareableLink,
                    hash: hashify(normalizedLabel)
                  },
                  checked: $event.target.checked
                })">
            </template>
          </template>
          <template v-else>
            <input
              v-if="data.content"
              type="checkbox"
              class="js-share-button"
              :data-hash="hashify(normalizedLabel)"
              @change="$emit('share-card', {
                data: {
                  shareLabel: normalizedLabel,
                  shareLink: shareableLink,
                  hash: hashify(normalizedLabel)
                },
                checked: $event.target.checked
              })">
          </template>
        </div>
      </div>

      <div class="main-card__text">
        <span v-if="data.filters" class="category">
          <template v-for="(industry, index) in data.filters.industry" :key="index">
            {{ industry.name }}<span v-if="index != data.filters.industry.length - 1">, </span>
          </template>
        </span>

        <h3 class="title title--small" v-if="data.content">
          <template v-if="dataSection === 'Library'">
            {{ data.content.name }}
          </template>
          <template v-else>
            {{ data.content.title }}
          </template>
        </h3>

        <Button
          v-if="targetModal"
          :class="{'small': $i18n.locale === 'fr'}"
          data-toggle="modal"
          :data-target="targetModal"
          :data-gtm-section="dataSection"
          :data-gtm-subsection="dataSubsection"
          :data-gtm-label="`${data.identifier} - Open Detail`"
          @click="$emit('show-modal', idCard); clickTracked($event)" >
          <template v-if="buttonText">
            {{ buttonText }}
          </template>
          <template v-else>
            {{ $t('buttons.learn-more') }}
          </template>
        </Button>

        <Button
          v-else
          :href="data.asset"
          target="_blank"
          :data-target="targetModal"
          :data-gtm-section="dataSection"
          :data-gtm-subsection="dataSubsection"
          :data-gtm-label="`${data.identifier} - Click Link`"
          @click="clickTracked($event)">
          <template v-if="buttonText">
            {{ buttonText }}
          </template>
          <template v-else>
            {{ $t('buttons.learn-more') }}
          </template>
        </Button>
      </div>
    </template>
  </div>
</template>

<script>
import * as endpoints from '@/common/endpoints';
import { defaultMixins, filters } from '@/common/mixins';

import Button from '@/components/ui/Button.vue';

export default {
  name: 'card',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    cardType: {
      type: String
    },
    featured: {
      type: Boolean
    },
    buttonText: {
      type: String
    },
    data: {
      required: true,
      type: Object
    },
    idCard: {
      type: Number
    },
    targetModal: {
      type: String
    },
    dataSection: {
      type: String
    },
    dataSubsection: {
      type: String
    },
    shareSection: {
      type: String
    },
    shareSubsection: {
      type: String
    }
  },
  emits: ['share-card', 'show-modal'],
  components: {
    Button
  },
  mixins: [defaultMixins, filters],
  data() {
    return {
      baseURL: endpoints.BASEURL
    };
  },
  computed: {
    normalizedLabel() {
      const products = (this.data.content.product)
        ? ` (${this.data.content.product.replace(/<\/?[^>]+(>|$)/g, ' ')})`
        : '';
      const prefix = (this.shareSubsection) ? this.shareSubsection : this.shareSection;
      let name = null;

      if (!this.featured) {
        if (this.dataSection === 'Customer Stories') {
          name = this.data.content.title;
        } else {
          name = this.data.content.name;
        }
      } else {
        name = this.data.content.name;
      }

      return `${prefix} - ${name}${products}`;
    },
    shareableLink() {
      const link = (this.data.article_link) ? `${this.data.article_link}\n` : '';
      const website = (this.data.website) ? `${this.data.website}\n` : '';
      const brochure = (this.data.brochure) ? `${this.cmsURL}assets/${this.data.brochure}\n` : '';
      const pdf = (this.data.content.shareable_pdf) ? `${this.cmsURL}assets/${this.data.content.shareable_pdf}\n` : '';
      const fullstory = (this.data.content.fullstory_pdf) ? `${this.cmsURL}assets/${this.data.content.fullstory_pdf}\n` : '';

      let asset = '';
      let video = '';

      if (this.data.asset) {
        if (this.data.asset.indexOf('.mp4') >= 0) {
          asset = `${this.baseURL}${this.data.asset}\n`;
        } else if (this.data.asset.indexOf('pdf/') >= 0) {
          asset = `${this.baseURL}${this.data.asset}\n`;
        } else {
          asset = `${this.data.asset}\n`;
        }
      }

      if (this.data.asset_pdf) {
        asset = `${this.cmsURL}assets/${this.data.asset_pdf}\n`;
      }

      if (this.data.video) {
        video = (this.data.video.indexOf('.mp4') >= 0) ? `${this.baseURL}${this.data.video}\n` : `${this.data.video}\n`;
      }

      return `${pdf}${asset}${brochure}${video}${website}${link}${fullstory}`;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/components/card/card.scss';
</style>
