<template>
  <div class="row">
    <div class="col-md-7 col-lg-5 offset-md-5 offset-lg-7">
      <template
        v-for="(item, index) in data.vission_mission_content"
        :key="index">
        <div
          class="statement"
          >
          <h2 class="title title--big">
            {{ item.headline }}
          </h2>
          <p>
            {{ item.content }}
          </p>
        </div>
        <div class="statement__line"
          v-if="index != Object.keys(data.vission_mission_content).length - 1"></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VisionMission',
  props: {
    data: Object
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/about/vision-mission.scss';
</style>
