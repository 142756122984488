<template>
  <div v-if="info">
    <div  v-if="!shareableVersion" class="container-xl">
      <div class="container-xl">
        <div class="section__title text-center">
          <h2 class="title">
            {{ subSections[currentSubsection].title }}
          </h2>
        </div>

        <Subsection
          :currentComponent="subSections[currentSubsection]"
          gtmSubsection="Tower Of Production"
          parent="Upgradability"
          :subSections="subSections"
          @click-subnav="changeTab" >
            <Tower
              :layout="subSections[currentSubsection].layout"
              :data="getData(currentSubsection)" />
        </Subsection>
      </div>
    </div>
    <div v-else>
      <div
        v-for="item in subSections"
        :key="item.id"
        class="container-xl">
        <div class="section__title text-center">
          <h2 class="title">
            {{ subSections[item.id].title }}
          </h2>
        </div>
        <Subsection
          gtmSubsection="Tower Of Production"
          parent="Upgradability"
          :currentComponent="subSections[item.id]"
          :subSections="subSections"
          :isShareable="true"
          @click-subnav="changeTab">
          <Tower
            :layout="subSections[item.id].layout"
            :data="getData(item.id)" />
        </Subsection>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

// import ShareButton from '@/components/ui/ShareButton.vue';
import Subsection from '@/layout/SubsectionNavLeft.vue';

import Tower from './tower-of-production/Tower.vue';

export default {
  name: 'TowerOfProduction',
  mixins: [filters, defaultMixins],
  components: {
    // ShareButton,
    Subsection,
    Tower
  },
  data() {
    return {
      baseURL: endpoints.BASEURL,
      currentSubsection: 0,
      info: null
    };
  },
  created() {
    // Get Intro info
    axios.get(`${endpoints.CMS}items/tower_of_production`, {
      params: {
        fields: '*,printer.printers_id,translations.*'
      }
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1'
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.hideMainTabs();
  },
  methods: {
    changeTab(payload) {
      this.currentSubsection = payload.id;
    }
  },
  computed: {
    // Get current Printer's data
    getDataByPrinter() {
      if (this.info) {
        const currentPrinterData = this.info
          .filter((item) => item.printer
            .some((printer) => printer.printers_id === this.currentPrinter));

        return currentPrinterData;
      }

      return false;
    },
    // Get Translated data
    getData() {
      return (idx) => {
        if (this.info) {
          // Set fallback to english data
          const [defaultEnglishData] = this.getDataByPrinter[idx].translations
            .filter((item) => item.languages_code === this.$i18n.fallbackLocale);

          // Get current language data
          const [translatedData] = this.getDataByPrinter[idx].translations
            .filter((item) => item.languages_code === this.$i18n.locale);

          return translatedData || defaultEnglishData;
        }

        return false;
      };
    },
    /**
     * The items in the array MUST match the components name files
     * these values are trimmed and passed as components names.
     */
    subSections() {
      const mapSections = this.getDataByPrinter.map((item, id) => (
        {
          id,
          component: 'Tower',
          label: this.getData(id).tab_name,
          title: this.getData(id).headline,
          shareLabel: this.getData(id).tab_name,
          layout: this.getData(id).layout
        }
      ));

      return mapSections;
    }
  }
};
</script>

<style lang="scss" scoped>
  .wrapper-sub {
    display: flex;
    align-items: flex-start;
  }
</style>
