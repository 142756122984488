<template>
  <div class="section--top virtual-tour">
    <!-- eslint-disable max-len  -->
    <Loading v-if="isLoading" />

    <!-- Virtual Tour Main Title -->
    <div class="virtual-tour__main-title text-center js-main-title" v-if="info">
      <h2 class="title" v-html="getData.headline"></h2>
      <h5
        class="title title--small js-subtitle"
        v-if="getData.description"
        v-html="getData.description"></h5>
    </div>
    <!-- // Virtual Tour Main Title -->

    <!-- Back Button -->
    <div
      class="virtual-tour__back d-flex"
      ref="goBack"
      @click="goBackTour">
      <svg class="mr-2" width="16px" height="25px" viewBox="0 0 16 25">
        <path class="st0" d="M10.8,2.5C10.8,3.9,12,5,13.4,5S16,3.9,16,2.5S14.8,0,13.4,0S10.8,1.1,10.8,2.5"/>
        <path class="st0" d="M10.8,12.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S14.8,10,13.4,10S10.8,11.1,10.8,12.5"/>
        <path class="st0" d="M10.8,22.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S14.8,20,13.4,20S10.8,21.1,10.8,22.5"/>
        <path class="st0" d="M5.7,7.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S9.7,5,8.3,5S5.7,6.1,5.7,7.5"/>
        <path class="st0" d="M5.7,17.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S9.7,15,8.3,15S5.7,16.1,5.7,17.5"/>
        <path class="st0" d="M0.5,12.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S4.5,10,3.1,10S0.5,11.1,0.5,12.5"/>
      </svg>
      {{ $t('common.go-back') }}
    </div>
    <!-- // Back Button -->

    <!-- Main Image & Points -->
    <img
      :src="`${cmsURL}assets/${getDataByPrinter.custom_background}`"
      class="virtual-tour__main-printer img-fluid js-main-bg" />
    <img
      src="images/virtual-tour/virtual-tour-lighting.png"
      class="virtual-tour__lighting img-fluid js-main-light" />

    <div class="virtual-tour__points-holder" ref="pointsHolder">
      <template
        v-for="(point, index) in points"
        :key="index" >
        <Hotspot
          parent="Virtual Tour"
          subSection="Intro"
          :class="[`main-point--${point.index}`, 'js-main-hotspot']"
          :gtmLabel="`Click Point ${point.index}`"
          :position="point.position"
          :style="{
            top: point.top,
            left: point.left
          }"
          :title="point.title"
          @click="openTour(point); clickTracked($event)">
          {{ point.index }}
        </Hotspot>
      </template>
    </div>
    <!-- // Main Image & Points -->

    <!-- Transition Animation -->
    <div class="virtual-tour__trans" ref="wrapTransitions">
      <canvas ref="animCanvas"></canvas>
    </div>
    <!-- // Transition Animation -->

    <!-- Tour Sections -->
    <transition name="fade">
      <component
        :bg="bgSection"
        :customClass="customSectionClass"
        :data="infoSection"
        :is="currentComponent"/>
    </transition>
    <!-- // Tour Sections -->

    <!-- Subnav Tour -->
    <div class="virtual-tour__sub-nav p-3" v-show="currentComponent">
      <a
        v-for="(point, index) in points"
        :class="['ra-gtm-event', {'active': currentSection === point.index}]"
        href="javascript:void(0)"
        data-gtm-label="Click bottom navigation"
        data-gtm-section="Virtual Tour"
        :data-gtm-subsection="gtmLabels[index].label"
        :data-title="point.title"
        :key="index"
        @click="changeTour(point); clickTracked($event)">
            {{ point.index }}
          <span>
            {{ point.title }}
          </span>
      </a>
    </div>
    <!-- // Subnav Tour -->

    <!-- 360 View Button -->
    <div
      class="virtual-tour__360--btn ra-gtm-event"
      data-gtm-section="Virtual Tour"
      data-gtm-subsection="360 View"
      data-gtm-label="Open 360 View"
      v-show="!currentComponent"
      @click="open360(); clickTracked($event)">
      <a href="javascript:void(0)">
        <svg id="Icon_360_View" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.664 22.355">
          <path class="path-0" d="M17.561,2.958A1.5,1.5,0,1,1,18.276.044c7.391,1.813,11.139,4.3,11.139,7.408,0,6.137-14.919,8.429-21.333,9.131-2.09.228-4.277.4-6.5.52l-.081,0a1.5,1.5,0,0,1-.078-3c2.17-.114,4.3-.285,6.334-.507,14.1-1.54,18.66-4.808,18.66-6.148,0-.8-1.85-2.777-8.854-4.494" transform="translate(40.249 0.069)" />
          <path class="path-0" d="M11.435.042a1.5,1.5,0,1,1,.7,2.918C4.91,4.689,3,6.7,3,7.521c0,2.241,9.656,6.3,28.163,6.785l-2.394-2.394A1.5,1.5,0,0,1,30.89,9.791l5,5a1.644,1.644,0,0,1,.187.228c.013.021.022.045.036.066a1.394,1.394,0,0,1,.1.191c.013.032.02.065.031.1a1.61,1.61,0,0,1,.055.183,1.485,1.485,0,0,1,0,.594,1.61,1.61,0,0,1-.055.183c-.011.032-.018.065-.031.1a1.394,1.394,0,0,1-.1.191c-.014.021-.023.045-.036.066a1.644,1.644,0,0,1-.187.228l-5,5A1.5,1.5,0,0,1,28.769,19.8L31.26,17.3C15.194,16.873,0,13.532,0,7.521,0,4.375,3.848,1.858,11.435.042" />
        </svg>
        <span>
          360°
        </span>
      </a>
    </div>
    <!-- // 360 View Button -->

    <transition name="fade">
      <Tour360
        v-show="is360visible"
        @close="close360" />
    </transition>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import { gsap } from 'gsap';

import * as endpoints from '@/common/endpoints';
import { defaultMixins } from '@/common/mixins';

import Loading from '@/components/ui/Loading.vue';
import Hotspot from '@/components/ui/VirtualTourPoint.vue';

import TourCard from '@/views/virtual-tour/TourCard.vue';
import TourInnerPoints from '@/views/virtual-tour/TourInnerPoints.vue';
import TourSimple from '@/views/virtual-tour/TourSimple.vue';
import Tour360 from '@/views/virtual-tour/Tour360.vue';

const tourSectionsMapping = {
  1: {
    Tour01: 'TourCard',
    Tour02: 'TourInnerPoints',
    Tour03: 'TourSimple',
    Tour04: 'TourInnerPoints',
    Tour05: 'TourSimple',
    Tour06: 'TourSimple',
    Tour07: 'TourSimple',
    Tour08: 'TourSimple',
    Tour09: 'TourSimple',
    Tour10: 'TourSimple',
    Tour11: 'TourCard'
  },
  2: {
    Tour01: 'TourInnerPoints',
    Tour02: 'TourInnerPoints',
    Tour03: 'TourInnerPoints',
    Tour04: 'TourSimple',
    Tour05: 'TourSimple',
    Tour06: 'TourInnerPoints',
    Tour07: 'TourSimple',
    Tour08: 'TourSimple',
    Tour09: 'TourInnerPoints'
  },
  3: {
    Tour01: 'TourInnerPoints',
    Tour02: 'TourSimple',
    Tour03: 'TourSimple',
    Tour04: 'TourSimple',
    Tour05: 'TourSimple',
    Tour06: 'TourSimple',
    Tour07: 'TourSimple',
    Tour08: 'TourSimple',
    Tour09: 'TourSimple',
    Tour10: 'TourSimple',
    Tour11: 'TourSimple',
    Tour12: 'TourSimple',
    Tour13: 'TourSimple',
    Tour14: 'TourInnerPoints'
  }
};

export default {
  name: 'VirtualTour',
  mixins: [defaultMixins],
  components: {
    Loading,
    Hotspot,
    TourCard,
    TourInnerPoints,
    TourSimple,
    Tour360
  },
  data() {
    return {
      animation: {
        imgSequence: [],
        currentFrame: 0,
        lastFrame: -1,
        totalFrames: 24
      },
      bgSection: '',
      canvas: null,
      cmsURL: endpoints.CMS,
      currentSection: null,
      currentIdentifier: null,
      currentComponent: null,
      info: null,
      infoSection: [],
      timelineIntro: null,
      timeline: null,
      rawPointsInfo: [],
      isLoading: false,
      is360visible: false
    };
  },
  created() {
    // Get Intro info
    axios.get(`${endpoints.CMS}items/virtual_tour`, {
      params: {
        fields: '*,custom_background,translations.*,printer.printers_id',
        'filter[identifier][_eq]': 'TourIntro'
      }
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1'
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });

    // Get all Points info
    axios.get(`${endpoints.CMS}items/virtual_tour`, {
      params: {
        fields: `*,
          translations.languages_code,
          translations.hotspot_number,
          translations.hotspot_label,
          translations.label_position,
          printer.printers_id`,
        'filter[identifier][_neq]': 'TourIntro'
      }
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1'
    })
      .then(({ data: { data } }) => {
        this.rawPointsInfo = data;
      });
  },
  mounted() {
    this.showMainTabs();

    this.$nextTick(() => {
      this.checkAndAutoloadPoint();
    });
  },
  updated() {
    this.showMainTabs();

    this.pointsHolderAspectRatio();
    window.addEventListener('resize', this.pointsHolderAspectRatio);

    // this.timelineIntro.play();
    // set canvas for animations
    this.canvas = this.$refs.animCanvas;
  },
  unmounted() {
    window.removeEventListener('resize', this.pointsHolderAspectRatio);
  },
  methods: {
    // Autoload points listening to the url params
    checkAndAutoloadPoint() {
      const { id } = this.$route.params;
      if (id) {
        this.isLoading = true;

        setTimeout(() => {
          const target = this.points.find((point) => point.index === id);

          if (target) {
            this.openTour(target);
          } else {
            this.isLoading = false;
          }
        }, 1000);
      }
    },
    // Fetch Current section Data
    fetchTourSectionData(target) {
      this.infoSection = [];

      axios.get(`${endpoints.CMS}items/virtual_tour`, {
        params: {
          fields: '*,translations.*,printer.printers_id',
          'filter[identifier][_eq]': target
        }
      },
      {
        'Cache-Control': 'no-cache',
        Expires: '-1'
      })
        .then(({ data: { data } }) => {
          const [currentPrinterData] = data.filter((item) => item.printer
            .some((printer) => printer.printers_id === this.currentPrinter));

          const [defaultEnglishData] = currentPrinterData.translations
            .filter((item) => item.languages_code === this.$i18n.fallbackLocale);

          const [translatedData] = currentPrinterData.translations
            .filter((item) => item.languages_code === this.$i18n.locale);

          this.bgSection = currentPrinterData.custom_background;
          this.infoSection = translatedData || defaultEnglishData;
        });
    },
    openTour(objTarget) {
      this.currentSection = objTarget.index;
      this.currentIdentifier = objTarget.identifier;

      // Show loader
      this.isLoading = true;

      // Active class transition
      this.$refs.wrapTransitions.classList.add('active');

      // Run aminations
      this.loadTextures(this.animation.totalFrames)
        .then(this.setArrayOfImages)
        .then(this.fetchTourSectionData(objTarget.identifier))
        .then(() => {
          // Attach animation to local Data Property
          this.timeline = this.animationSequence(objTarget);
          // Hide Loader
          this.isLoading = false;
        })
        .then(() => this.timeline.play())
        .then(() => this.hideMainTabs());
    },
    changeTour(objTarget) {
      this.currentSection = objTarget.index;
      this.currentIdentifier = objTarget.identifier;

      // Show loader
      this.isLoading = true;

      // Active class transition
      this.$refs.wrapTransitions.classList.add('active');

      // Run aminations
      this.loadTextures(this.animation.totalFrames)
        .then(this.setArrayOfImages)
        .then(this.fetchTourSectionData(objTarget.identifier))
        .then(() => {
          // Hide Loader
          this.isLoading = false;
        })
        .then(() => {
          const ctx = this.canvas.getContext('2d');

          ctx.drawImage(this.animation.imgSequence[23],
            0, 0,
            this.canvas.width, this.canvas.height);

          // Set Current component based on Mapping Object
          this.currentComponent = tourSectionsMapping[this.currentPrinter][objTarget.identifier];
        })
        .then(() => this.hideMainTabs());
    },
    goBackTour() {
      this.currentComponent = null;
      this.$refs.goBack.classList.remove('active');
      this.timeline.reverse();
    },
    open360() {
      this.is360visible = true;
    },
    close360() {
      this.is360visible = false;
    },
    // Methods for animating img sequence
    animationSequence(objTarget) {
      return gsap.timeline({
        onUpdate: this.renderAnimation,
        paused: true
      })
        .to(this.animation, {
          currentFrame: this.animation.totalFrames - 1,
          roundProps: 'currentFrame',
          duration: 1,
          onComplete: () => {
            this.$refs.goBack.classList.add('active');

            // Set Current component based on Mapping Object
            this.currentComponent = tourSectionsMapping[this.currentPrinter][objTarget.identifier];
          },
          onReverseComplete: () => {
            this.$refs.wrapTransitions.classList.remove('active');
          }
        })
        .timeScale(0.6);
    },
    setArrayOfImages(allImages) {
      const baseImg = allImages[0];

      this.animation.imgSequence = allImages;
      this.canvas.width = baseImg.naturalWidth || baseImg.width;
      this.canvas.height = baseImg.naturalHeight || baseImg.height;
    },
    loadImage(path) {
      return new Promise(((resolve) => {
        const img = new Image();

        img.onload = () => {
          resolve(img);
        };

        img.src = path;
      }));
    },
    loadTextures(numTextures) {
      const printerFolder = {
        1: 't250',
        2: 't490',
        3: 'a2200'
      };

      const idxBasedOnNumber = this.currentSection < 10 ? `0${this.currentSection}` : this.currentSection;

      const baseURL = `images/virtual-tour/sequences/${printerFolder[this.currentPrinter]}/${idxBasedOnNumber}-`;
      const promises = [];
      for (let i = 1; i <= numTextures; i += 1) {
        const index = i < 10 ? `0${i}` : i;
        // loadImage returns a promise. It resolves when image is loaded
        promises.push(this.loadImage(`${baseURL + index}.jpg`));
      }
      // Resolves when all the promises are resolved
      return Promise.all(promises);
    },
    renderAnimation() {
      const ctx = this.canvas.getContext('2d');
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      ctx.drawImage(this.animation.imgSequence[this.animation.currentFrame],
        0, 0,
        this.canvas.width, this.canvas.height);
    },
    pointsHolderAspectRatio() {
      // ** force w & h to keep points positioned
      const winWidth = window.innerWidth;
      let imgH = window.innerHeight;
      let imgW = imgH * (16 / 9);

      if (winWidth > imgW) {
        imgH = imgW / (16 / 9);
        imgW = window.innerWidth;
      } else {
        imgH = window.innerHeight;
        imgW = imgH * (16 / 9);
      }

      this.$refs.pointsHolder.style.width = `${imgW}px`;
      this.$refs.pointsHolder.style.height = `${imgH}px`;
    }
  },
  watch: {
    is360visible(newValue) {
      if (newValue === true) {
        return this.hideMainTabs();
      }
      return this.showMainTabs();
    },
    // eslint-disable-next-line func-names
    '$root.$i18n.locale': function () {
      this.fetchTourSectionData(this.currentIdentifier);
    }
  },
  computed: {
    customSectionClass() {
      // const printerClass = (this.currentPrinter === 1) ? 't250' : 't490';
      const printerClass = {
        1: 't250',
        2: 't490',
        3: 'a2200'
      };

      return `section--${printerClass[this.currentPrinter]}-${this.currentSection}`;
    },
    getPointsByPrinter() {
      if (this.rawPointsInfo) {
        return this.rawPointsInfo
          .filter((point) => point.printer
            .some((printer) => printer.printers_id === this.currentPrinter));
      }

      return false;
    },
    points() {
      return this.getPointsByPrinter
        // Translate points
        .map((point) => {
          // Filter translated items
          const translatedPoint = point.translations
            .filter((i) => i.languages_code === this.$i18n.locale)[0];
          // Set English for fallback
          const fallbackPoint = point.translations
            .filter((i) => i.languages_code === this.$i18n.fallbackLocale)[0];

          return {
            ...point,
            content: translatedPoint || fallbackPoint
          };
        })
        // Map data into desired object format
        .map((point) => (
          {
            identifier: point.identifier,
            index: point.content.hotspot_number,
            left: point.left,
            position: point.content.label_position,
            title: point.content.hotspot_label,
            top: point.top
          }
        ));
    },
    gtmLabels() {
      return this.getPointsByPrinter
        // Translate points
        .map((point) => {
          // Set English for fallback
          const fallbackPoint = point.translations
            .filter((i) => i.languages_code === this.$i18n.fallbackLocale)[0];

          return {
            label: fallbackPoint.hotspot_label
          };
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/virtual-tour/intro.scss';
</style>
