<template>
  <div class="section--top library">
    <transition name="fade">
      <Loading v-if="!isReady" />
    </transition>

    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ $t('main-nav.library') }}
        </h2>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="wrap-sidebar-filter">
            <span>
              {{ $t('filters.filtering-options') }}
            </span>
            <div class="sidebar-filter">
              <div class="sidebar-filter__list">
                <template v-for="(filter, index) in translatedFilters" :key="index">
                  <input
                    type="checkbox"
                    :value="filter.asset_type_id"
                    :id="slugify(filter.name)"
                    v-model="selectedFilters">
                  <label :for="slugify(filter.name)">
                    <span class="input">
                      <svg width="12px" height="10px">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </svg>
                    </span>
                    <span class="label">
                      {{ filter.name }}
                    </span>
                  </label>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="row">
            <template v-for="(item, index) in filteredCards" :key="index">
              <div class="col-sm-6 col-lg-4 col-xl-3">
                <Card
                  :button-text="item.filters.type[0].asset_type_id === 3 ?
                  `${$t('buttons.watch-video')}` : `${$t('buttons.download')}`"
                  card-type="library"
                  class="data-library-card"
                  :data="item"
                  data-section="Library"
                  :id-card="index"
                  :share-section="`${$t('main-nav.library')}`"
                  :target-modal="'#modal-library-' + index"
                  @share-card="shareThis"
                  @show-modal="setOpen" />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <template v-for="(item, index) in filteredCards" :key="index">
      <Modal
        :idModal="'modal-library-' + index" >
          <!-- VIDEO ASSET MODAL -->
          <template v-if="item.filters.type[0].asset_type_id === 3">
            <Media
              v-if="item.content.asset"
              data-gtm-section="Library"
              :data-gtm-label="`${item.identifier} - Watch Video`"
              :poster="`${cmsURL}assets/${item.image}`"
              :src="item.asset"
              :title="item.content.name" />
          </template>
          <!-- PDF ASSET MODAL -->
          <template v-else>
            <div class="wrap-modal-pdf">
              <iframe :src="item.content.asset" frameborder="0" :title="item.content.name"></iframe>
            </div>
          </template>
      </Modal>
    </template>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import axios from 'axios';
import $ from 'jquery';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins, cardsMixins } from '@/common/mixins';
// import { newHeight } from '@/common/functions';

import Loading from '@/components/ui/Loading.vue';
import Card from '@/components/card/Card.vue';
import Modal from '@/components/modal/Modal.vue';
import Media from '@/components/ui/Media.vue';

export default {
  name: 'Library',
  components: {
    Loading,
    Card,
    Modal,
    Media
  },
  mixins: [filters, defaultMixins, cardsMixins],
  data() {
    return {
      baseURL: endpoints.BASEURL,
      filters: [],
      items: [],
      selectedFilters: []
    };
  },
  created() {
    // Get all Filters
    axios.get(`${endpoints.CMS}items/asset_type`, {
      params: {
        fields: '*,translations.*'
      }
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1'
    })
      .then(({ data: { data } }) => { this.filters = data; });

    // Get all Cards
    axios.get(`${endpoints.CMS}items/library`, {
      params: {
        fields: `*,
          translations.*,
          filters_asset_type.asset_type_id.*,
          filters_asset_type.asset_type_id.translations.*`,
        sort: 'sort',
        'filter[status][_eq]': 'published'
      }
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1'
    })
      .then(({ data: { data } }) => { this.items = data; })
      .then(() => { this.isReady = true; });
  },
  mounted() {
    this.hideMainTabs();
  },
  updated() {
    this.matchHeight('.data-library-card .title');
  },
  methods: {
    updateShared(sharedArr) {
      $('.data-library-card .js-share-button').prop('checked', false);

      sharedArr.map((item) => $(`.data-library-card .js-share-button[data-hash="${item.hash}"]`).prop('checked', true));
    }
  },
  computed: {
    // Print filters filtered with current locale
    translatedFilters() {
      return this.filters
        .map((filter) => {
          // Set English for fallback
          const translatedFilter = filter.translations
            .filter((i) => i.languages_code === this.$i18n.locale)[0];
          // Filter translated items
          const fallbackFilter = filter.translations
            .filter((i) => i.languages_code === this.$i18n.fallbackLocale)[0];

          return translatedFilter || fallbackFilter;
        });
    },
    // Filter the translated data inside the cards
    translatedCards() {
      return this.items
        .filter((card) => card.translations
          .some((lang) => lang.languages_code === this.$i18n.locale))
        .map((card) => {
          const [translatedCard] = card.translations
            .filter((i) => i.languages_code === this.$i18n.locale);
          return {
            ...card,
            content: translatedCard
          };
        })
        .map((card) => (
          {
            ...card,
            filters: {
              ...card.filters,
              type: this.getTranslatedFilter(card, 'asset_type')
            }
          }
        ));
    },
    // Return cards after filtering in the front-end
    filteredCards() {
      const hasfilters = this.selectedFilters.length;
      const allData = this.translatedCards;

      if (hasfilters === 0) {
        return allData;
      }

      return allData
        .filter((card) => this.doFilter(card, 'asset_type', false));
    }
  }
};
</script>

<style lang="scss" scoped>
.section__title {
  display: block;
  margin-bottom: 60px;
}

.sidebar-filter {
  margin-top: 20px;
  padding-top: 7px;
  border-top: 1px solid white;
}

.sidebar-filter__list {
  label {
    position: relative;
    display: block;
    padding: 6px 8px;
    border-radius: 6px;
    cursor: pointer;
    transition: all .2s ease;

    user-select: none;
    user-select: none;

    span {
      display: inline-block;
      vertical-align: middle;
    }

    .input {
      position: relative;
      width: 25px;
      height: 25px;
      border: 1px solid #CCCFDB;
      border-radius: 6px;
      background-color: #63656B;
      box-shadow: 0 1px 1px rgba(#00104B,.05);
      transition: all .2s ease;

      svg {
        position: absolute;
        top: 6px;
        left: 5px;
        opacity: 0;
        transition: all .3s ease;

        fill: none;
        stroke: $color_blue;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }

    .label {
      padding-left: 8px;
      color: white;
      font-size: 16px;
      font-family: $font_light;
      line-height: 18px;
    }

    &:hover .input {
      border-color: $color_blue;
    }
  }

  input {
    position: absolute;
    visibility: hidden;
    height: 25px;

    &:checked + label {
      .input {
        border-color: $color_blue;
        background-color: white;

        svg {
          opacity: 1;
        }
      }

      .label {
        font-family: $font_default;
      }
    }
  }
}
</style>
