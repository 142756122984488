<template>
  <div class="profile">
    <!-- eslint-disable max-len  -->

    <!-- Back Button -->
    <router-link
      class="go-back d-flex"
      to="/">
      <svg class="mr-2" width="16px" height="25px" viewBox="0 0 16 25">
        <path class="st0" d="M10.8,2.5C10.8,3.9,12,5,13.4,5S16,3.9,16,2.5S14.8,0,13.4,0S10.8,1.1,10.8,2.5"/>
        <path class="st0" d="M10.8,12.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S14.8,10,13.4,10S10.8,11.1,10.8,12.5"/>
        <path class="st0" d="M10.8,22.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S14.8,20,13.4,20S10.8,21.1,10.8,22.5"/>
        <path class="st0" d="M5.7,7.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S9.7,5,8.3,5S5.7,6.1,5.7,7.5"/>
        <path class="st0" d="M5.7,17.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S9.7,15,8.3,15S5.7,16.1,5.7,17.5"/>
        <path class="st0" d="M0.5,12.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S4.5,10,3.1,10S0.5,11.1,0.5,12.5"/>
      </svg>
      {{ $t('common.return') }}
    </router-link>

    <div class="container-xl">
      <div class="section__content">
        <h2 class="title text-center mb-3">
        {{ $t('main-sidebar.profile') }}
        </h2>

        <transition name="fade" mode="out-in">
          <div class="profile-card" v-if="!isEditingMainProfile">
            <div class="row align-items-center">
              <div class="col-md-4">
                <div class="profile-card__img">
                  <img
                    :alt="`${salesPerson.firstname} ${salesPerson.lastname}`"
                    :src="salesPerson.photo || 'images/headshot.jpg'" >
                </div>
              </div>
              <div class="col-md-8">
                <div class="profile-card__info">
                  <h4>
                    {{ salesPerson.firstname }} {{ salesPerson.lastname }}
                  </h4>
                  <p>
                    {{ salesPerson.title }}
                  </p>
                  <a
                    href="javascript:void(0)"
                    @click="editMainProfile">
                    <i class="fa fa-pencil"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade" mode="out-in">
          <form
            class="profile-form js-profile-form"
            v-if="isEditingMainProfile">
            <div class="profile-picture">
              <div class="wrap-pic">
                <img
                  :alt="`${salesPerson.firstname} ${salesPerson.lastname}`"
                  class="pic"
                  :src="salesPerson.photo || 'images/headshot.jpg'" >
              </div>
              <i
                aria-hidden="true"
                class="fa fa-camera"
                @click="editMainProfileImg"></i>

              <div class="profile-img-edit" v-show="isEditingMainProfileImg">
                <input
                  type="file"
                  class="imageFile"
                  name="file"
                  @change="onFileChange" />
                <input
                  type="button"
                  class="button"
                  :value="$t('buttons.upload')"
                  @click="uploadImage">
                <small>
                  {{ $t('common.click-icon') }}
                </small>
              </div>
            </div>

            <h5 class="divider l-spacing-2 pb-1">
              {{ $t('profile.edit') }}
            </h5>

            <div class="d-flex flex-column flex-md-row">
              <div class="form-group col-md-6 pl-0 pr-0 pl-md-0 pr-md-2 text-left">
                <label for="firstName">
                  {{ $t('profile.form.first-name') }}*
                </label>
                <input
                  class="form-control"
                  type="text"
                  name="firstName"
                  readonly
                  v-model="salesPerson.firstname">
              </div>

              <div class="form-group col-md-6 pl-0 pr-0 pr-md-0 pl-md-2 text-left">
                <label for="lastName">
                  {{ $t('profile.form.last-name') }}*
                </label>
                <input
                  class="form-control"
                  type="text"
                  name="lastName"
                  readonly
                  v-model="salesPerson.lastname">
              </div>
            </div>

            <div class="form-group text-left">
              <label for="email">
                {{ $t('profile.form.email') }}
              </label>
              <input
                class="form-control"
                type="email"
                name="emailAddress"
                readonly
                v-model="salesPerson.email">
            </div>

            <div class="d-flex flex-column flex-md-row flex-wrap mb-3">
              <div class="form-group col-md-6 px-0 pl-md-0 pr-md-2 text-left">
                <label for="region">
                  {{ $t('profile.form.region-label') }}
                </label>
                <input
                  class="form-control"
                  type="text"
                  name="region"
                  readonly
                  v-model="salesPerson.region">
              </div>

              <div class="form-group col-md-6 px-0 pr-md-0 pl-md-2 text-left">
                <label for="phoneNumber">
                  {{ $t('profile.form.phone') }}
                </label>
                <input
                  class="form-control"
                  type="text"
                  name="phoneNumber"
                  readonly
                  v-model="salesPerson.phone">
              </div>

              <div class="form-group col-md-6 px-0 pl-md-0 pr-md-2 text-left">
                <label for="title">
                  {{ $t('profile.form.title') }}
                </label>
                <input
                  class="form-control"
                  type="text"
                  name="title"
                  :placeholder="$t('profile.form.title')"
                  required
                  v-model="salesPerson.title">
              </div>

              <div class="form-group col-md-6 px-0 pl-md-0 pr-md-2 text-left">
                <label for="languuage">
                  {{ $t('profile.form.language.label') }}
                </label>
                <select
                  class="form-control"
                  name="language"
                  required
                  v-model="salesPerson.language">
                  <option value="" selected disabled>
                    {{ $t('profile.form.language.label') }}
                  </option>
                  <option value="en">
                    {{ $t('profile.form.language.en') }}
                  </option>
                  <option value="es">
                    {{ $t('profile.form.language.es') }}
                  </option>
                  <option value="de">
                    {{ $t('profile.form.language.de') }}
                  </option>
                  <option value="fr">
                    {{ $t('profile.form.language.fr') }}
                  </option>
                </select>
              </div>
            </div>

            <!-- <h5 class="divider l-spacing-2 pb-1">
              {{ $t('profile.form.password') }}
            </h5>

            <div class="d-flex flex-column flex-md-row">
              <div class="form-group col-md-6 pl-0 pr-0 pl-md-0 pr-md-2 text-left">
                <label for="">
                  {{ $t('profile.form.password') }}
                </label>
                <input
                  autocomplete="new-password"
                  class="form-control"
                  data-parsley-lowercase="1"
                  data-parsley-minlength="8"
                  data-parsley-number="1"
                  data-parsley-uppercase="1"
                  id="profilePass1"
                  name=""
                  required
                  type="password"
                  v-model="newpassword">
              </div>

              <div class="form-group col-md-6 pl-0 pr-0 pr-md-0 pl-md-2 text-left">
                <label for="">
                  {{ $t('profile.form.repeat-password') }}
                </label>
                <input
                  class="form-control"
                  data-parsley-equalto="#profilePass1"
                  id="profilePass2"
                  name=""
                  required=""
                  type="password"
                  v-model="repeatpassword">
              </div>

            </div> -->

            <div class="form-group my-3 mb-5">
              <button
                :class="['d-flex', 'align-items-center', 'justify-content-center', 'cta', 'ra-gtm-event', {'disabled': !profileHasChanges}]"
                data-gtm-section="My Profile"
                data-gtm-label="Update Profile"
                href="javascript:void(0)"
                type="submit"
                @click="saveProfile">
                <template v-if="isLoading">
                  {{ $t('common.actions.loading') }}
                  <i class="fa fa-spinner fa-pulse"></i>
                </template>
                <template v-else>
                  {{ $t('common.actions.update') }}
                </template>
              </button>
              <p class="text-center mt-1" v-if="!profileHasChanges">
                {{ $t('profile.profile-updated') }}
              </p>
            </div>
          </form>
        </transition>

        <div class="manage-team">
          <!-- * Additional Contacts -->
          <h5 class="divider l-spacing-2 pb-1">
            {{ $t('profile.additional-members') }}
          </h5>

          <!-- Repeater of team members -->
          <div class="team-members">
            <Draggable v-model="salesPerson.team" itemKey="name">
              <template #item="{element, index}">
                <MemberCard
                  :data="element"
                  :idx="index + 1"
                  :key="index"
                  @delete-member="deleteMember" />
              </template>
            </Draggable>
          </div>
          <!-- // Repeater of team members -->

          <!-- * Add Team Member -->
          <CreateMember
            v-show="isCreatingMember"
            :data="temporaryData"
            :idx="salesPerson.team.length + 1"
            @close-create="stopCreatingNewMember"/>
          <!-- Button -->
          <a
            href="javascript:void(0)"
            class="cta my-3 p-3 cta--grey ra-gtm-event"
            data-gtm-section="My profile"
            data-gtm-label="Add team member"
            v-show="!isCreatingMember && salesPerson.team.length < 7"
            @click="createNewMember">
            {{ $t('profile.add-member') }}
          </a>
          <!-- // * Add Team Member -->

          <a href="javascript:void(0)"
            :class="['cta', 'ra-gtm-event',
            {'disabled': !teamHasChanges}]"
            data-gtm-section="My Profile"
            data-gtm-label="Save Team"
            @click="saveTeam ">
            {{ $t('profile.save-team') }}
          </a>

          <p
            class="text-center mt-1"
            ref="teamSaved"
            v-show="!teamHasChanges">
            {{ $t('profile.team-saved') }}
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import { mapState } from 'vuex';
import Draggable from 'vuedraggable';

import * as endpoints from '@/common/endpoints';
import { loginMixins } from '@/common/mixins';
import basil from '@/common/cookies';

import MemberCard from './profile/MemberCard.vue';
import CreateMember from './profile/CreateMember.vue';

export default {
  name: 'Profile',
  mixins: [loginMixins],
  components: {
    Draggable,
    CreateMember,
    MemberCard
  },
  data() {
    return {
      isCreatingMember: false,
      isEditingMainProfile: false,
      isEditingMainProfileImg: false,
      isLoading: false,
      profileHasChanges: false,
      newpassword: 'Blank4Blank',
      repeatpassword: 'Blank4Blank',
      teamHasChanges: false,
      temporaryData: {
        firstname: '',
        lastname: '',
        title: ''
      },
      tempProfileImg: ''
    };
  },
  methods: {
    editMainProfile() {
      this.isEditingMainProfile = true;
      this.profileHasChanges = true;

      setTimeout(() => {
        this.parsleyTheForm('.js-profile-form');
      }, 500);
    },
    editMainProfileImg() {
      this.isEditingMainProfileImg = !this.isEditingMainProfileImg;
    },
    createNewMember() {
      this.isCreatingMember = true;
    },
    stopCreatingNewMember() {
      this.isCreatingMember = false;
    },
    deleteMember(id) {
      console.log('delete', id);
      this.$store.commit('removeMemberFromTeam', id);
      this.teamHasChanges = true;
    },
    goBack() {
      this.$store.commit('changeCurrentSection', 'Intro');
    },
    saveProfile() {
      this.isLoading = true;

      $('.js-profile-form').parsley().on('form:submit', () => {
        axios.post(
          `${endpoints.BASEAPIURL}teamfunctions${endpoints.EXT}`,
          {
            action: 'updateProfile',
            email: this.salesPerson.email,
            firstname: this.salesPerson.firstname,
            lastname: this.salesPerson.lastname,
            title: this.salesPerson.title,
            phone: this.salesPerson.phone,
            region: this.salesPerson.region,
            photo: this.salesPerson.photo,
            lang: this.salesPerson.language,
            password: this.newpassword
          }
        )
          .then((response) => {
            const json = response.data;

            if (json.reply === 1) {
              this.isLoading = false;
              this.isEditingMainProfile = false;
              this.teamHasChanges = false;
              $(this.$refs.teamSaved).show();

              this.$i18n.locale = json.language;
              basil.set('user', {
                ...json,
                team: this.salesPerson.team
              });

              console.log(basil.get('user'));

              setTimeout(() => {
                $(this.$refs.teamSaved).hide();
              }, 5000);
            } else {
              this.isLoading = false;
              this.profileHasChanges = false;
              console.error(json.message);
            }
          })
          .catch((error) => {
            console.error(error);
          });

        return false;
      });
    },
    saveTeam() {
      if (!this.teamHasChanges) {
        console.log('No edits');
        return false;
      }

      let teamString = '';
      for (let i = 0; i < this.salesPerson.team.length; i += 1) {
        if (i !== this.salesPerson.team.length - 1) {
          teamString += `${this.salesPerson.team[i].id},`;
        } else {
          teamString += this.salesPerson.team[i].id;
        }
      }

      axios.post(
        `${endpoints.BASEAPIURL}teamfunctions${endpoints.EXT}`,
        {
          action: 'updateArray',
          targetuser: this.salesPerson.email,
          teamstring: teamString
        }
      )
        .then((response) => {
          const json = response.data;
          console.log(json);

          if (json.reply === 1) {
            this.teamHasChanges = false;
            $(this.$refs.teamSaved).show();

            basil.set('user', this.salesPerson);

            setTimeout(() => {
              $(this.$refs.teamSaved).hide();
            }, 5000);
          } else {
            console.error(json.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      return false;
    },
    // Image main profile
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      [this.tempProfileImg] = files;
    },
    uploadImage() {
      const formData = new FormData();
      formData.append('file', this.tempProfileImg);
      formData.append('email', this.salesPerson.email);
      formData.append('action', 'salesperson');
      axios.post(
        `${endpoints.BASEAPIURL}uploadfile${endpoints.EXT}`,
        formData
      )
        .then((response) => {
          // console.log(response);
          const json = response.data;
          if (json.reply === 1) {
            this.salesPerson.photo = json.location;
            console.log(this.salesPerson);
            basil.set('user', this.salesPerson);

            this.profileHasChanges = true;

            $(this.$refs.imgOptions).slideUp();
          } else {
            console.error(json.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
  computed: {
    ...mapState([
      'salesPerson'
    ])
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/profile.scss';
</style>
