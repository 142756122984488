<template>
  <div v-if="info">
    <div
      v-if="!shareableVersion"
      class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ subSections[currentSubsection].label }}
        </h2>
      </div>

      <Subsection
        :currentComponent="subSections[currentSubsection]"
        gtmSubsection="HP TIJ"
        parent="Technology"
        :subSections="subSections"
        @click-subnav="changeTab">
        <component
          :data="getData"
          :is="subSections[currentSubsection].component" />
      </Subsection>
    </div>

    <div v-else>
      <div
        v-for="item in subSections"
        :key="item.id"
        class="container-xl">
        <div class="section__title text-center">
          <h2 class="title">
            {{ subSections[item.id].label }}
          </h2>
        </div>

        <Subsection
          :currentComponent="subSections[item.id]"
          gtmSubsection="HP TIJ"
          parent="Technology"
          :subSections="subSections"
          :isShareable="true"
          @click-subnav="changeTab">
          <component
            :data="getData"
            :is="subSections[item.id].component" />
        </Subsection>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>

    <ShareButton
      v-if="!shareableVersion && getData.shareable_pdf"
      :hash="hashify(`${$t('main-nav.technology')} - ${$t('technology.submenu.hp-tij')} ${printerName}`)"
      :shareLabel="`${$t('main-nav.technology')} - ${$t('technology.submenu.hp-tij')} ${printerName}`"
      :shareLink="`${baseURL}${getData.video}\n${cmsURL}assets/${getData.shareable_pdf}`"
      @share-this="shareThis" />
  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';
import Subsection from '@/layout/SubsectionNavLeft.vue';

import TIJVideo from './tij/TIJVideo.vue';
import HDNA from './tij/HDNA.vue';
import NozzleRedundancy from './tij/NozzleRedundancy.vue';
import Comparisson from './tij/Comparisson.vue';

export default {
  name: 'HPTIJ',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
    Subsection,
    TIJVideo,
    HDNA,
    NozzleRedundancy,
    Comparisson
  },
  data() {
    return {
      baseURL: endpoints.BASEURL,
      currentSubsection: 0,
      info: null
    };
  },
  created() {
    axios
      .get(
        `${endpoints.CMS}items/technology_tij`,
        {
          params: {
            fields: '*,translations.*,printer.printers_id'
          }
        },
        {
          'Cache-Control': 'no-cache',
          Expires: '-1'
        }
      )
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
  methods: {
    changeTab(payload) {
      this.currentSubsection = payload.id;
    }
  },
  computed: {
    /**
     * The items in the array MUST match the components name files
     * these values are trimmed and passed as components names.
     */
    subSections() {
      return [
        {
          id: 0,
          component: 'TIJVideo',
          label: this.getData.title_tij_video,
          shareLabel: 'TIJ Video'
        },
        {
          id: 1,
          component: 'HDNA',
          label: this.getData.hdna_title,
          shareLabel: 'HDNA'
        },
        {
          id: 2,
          component: 'NozzleRedundancy',
          label: this.getData.nozzle_title,
          shareLabel: 'Nozzle Redundancy'
        },
        {
          id: 3,
          component: 'Comparisson',
          label: this.getData.comparison_title,
          shareLabel: 'Thermal Vs Piezo'
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper-sub {
  display: flex;
  align-items: flex-start;
}
</style>
