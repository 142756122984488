<template>
  <div
    class="play-btn play-btn--big ra-gtm-event"
    :data-gtm-section="dataGtmSection"
    :data-gtm-subsection="dataGtmSubsection"
    :data-gtm-label="dataGtmLabel">
    <svg viewBox="0 0 21 26">
      <path d="M-0.08197,25.98361v0l20.65574,-12.68852v0l-20.65574,-12.68852v0z"
        fill="#ffffff">
      </path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'PlayButton',
  props: {
    dataGtmSection: {
      type: String
    },
    dataGtmSubsection: {
      type: String
    },
    dataGtmLabel: {
      type: String
    }
  }
};
</script>

<style lang="scss">
  @import '@/scss/components/ui/playButton.scss';
</style>
