<template>
  <Subsection
    class="section--top upgradability"
    :parent="parentSection"
    :sidebar="sidebar"
    :subSections="subSections">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </Subsection>
</template>

<script>
import { defaultMixins, filters } from '@/common/mixins';

import Subsection from '@/layout/SubsectionNavBottom.vue';

export default {
  name: 'Upgradability',
  mixins: [defaultMixins, filters],
  props: {
    sidebar: Boolean
  },
  components: {
    Subsection
  },
  computed: {
    subSections() {
      /**
       * The url key inside each element MUST match the router path
       * see: @/router/index.js
       */
      const mapOfSections = {
        1: [
          {
            label: this.$i18n.t('upgradability.submenu.path'),
            url: '',
            shareLabel: 'Upgradability Path'
          },
          {
            label: this.$i18n.t('upgradability.submenu.video'),
            url: 'upgradability-video',
            shareLabel: 'Upgradability Video'
          },
          {
            label: this.$i18n.t('upgradability.submenu.investment-scenario'),
            url: 'investment-scenario',
            shareLabel: 'Investment Scenario'
          }
        ],
        2: [
          {
            label: this.$i18n.t('upgradability.submenu.path'),
            url: '',
            shareLabel: 'Upgradability Path'
          },
          {
            label: this.$i18n.t('upgradability.submenu.video'),
            url: 'upgradability-video',
            shareLabel: 'Upgradability Video'
          }
        ],
        3: [
          {
            label: this.$i18n.t('upgradability.submenu.config-options'),
            url: 'configuration-options',
            shareLabel: 'Configuration Options'
          },
          {
            label: this.$i18n.t('upgradability.submenu.video2'),
            url: 'scalability-video',
            shareLabel: 'scalability Video'
          },
          {
            label: this.$i18n.t('upgradability.submenu.tower'),
            url: 'tower-of-production',
            shareLabel: 'Tower of Production'
          }
        ]
      };

      return mapOfSections[this.currentPrinter];
    },
    parentSection() {
      return this.currentPrinter === 3 ? 'Scalability' : 'Upgradability';
    }
  },
  methods: {
    changeSubSection(payload) {
      this.currentSubsection = payload;
    }
  }
};
</script>
