<template>
  <div class="wrap-sidetabs">
    <Sidenav
      v-if="!isShareable"
      :active="currentComponent"
      :items="subSections"
      :parent="parent"
      :subsection="gtmSubsection"
      @change-subsection="clickSubnav" />

    <div class="subsection--left">
      <transition
        name="fade"
        mode="out-in">
        <slot></slot>
      </transition>
    </div>
  </div>
</template>

<script>
import Sidenav from '../components/Sidenav.vue';

export default {
  name: 'SubsectionNavLeft',
  props: {
    currentComponent: Object,
    gtmSubsection: String,
    parent: String,
    subSections: Array,
    isShareable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click-subnav'],
  components: {
    Sidenav
  },
  methods: {
    clickSubnav(payload) {
      this.$emit('click-subnav', payload);
    }
  }
};
</script>

<style lang="scss">
.wrap-sidetabs {
  display: flex;
  align-items: flex-start;
}
.subsection--left {
  width: 100%;

  @include m768 {
    margin-left: 30px;
    flex: 0 1 auto;
  }
}
</style>
