<template>
  <div class="wrap-groups">
    <div class="col-left">
      <div
        class="wrap-graphics large-format">
        <h4 class="title title--medium">
          {{ data.groups[0].headline }}
        </h4>

        <div
          class="single-graphics single-graphics--large"
          v-for="(item, idx) in data.groups[0].item"
          :key="idx">
          <div class="img">
            <img :src="item.image" :alt="item.name">
          </div>
          <p>
            {{ item.name }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-right">
      <div
        :class="['wrap-graphics',
        {'commercial': index === 0},
        {'labels': index === 1}]"
        v-for="(group, index) in rightCol"
        :key="index">
        <h4 class="title title--medium">
          {{ group.headline }}
        </h4>

        <div
          class="single-graphics"
          v-for="(item, idx) in group.item"
          :key="idx">
          <div class="img">
            <img :src="item.image" :alt="item.name">
          </div>
          <p>
            {{ item.name }}
          </p>
        </div>
      </div>

      <!-- <div class="group-shape group-shape--pagewide">
        <img src="images/about-us/shape-gray.svg" alt="">
        <span>
          HP PageWide Industrial
        </span>
      </div>
      <div class="group-shape group-shape--indigo">
        <img src="images/about-us/shape-blue.svg" alt="">
        <span>
          HP Indigo
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Graphics',
  props: {
    data: Object
  },
  computed: {
    rightCol() {
      return this.data.groups.filter((i, idx) => idx > 0);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/about/hp-graphics.scss';
</style>
