<template>
  <div>
    <div class="subsection">
      <slot></slot>
    </div>

    <Subnav
      v-if="!shareableVersion"
      :class="{'sidebar': sidebar}"
      :items="subSections"
      :parent="parent" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Subnav from '@/components/Subnav.vue';

export default {
  name: 'SubsectionNavBottom',
  props: {
    parent: String,
    sidebar: Boolean,
    subSections: Array
  },
  components: {
    Subnav
  },
  computed: {
    // Maps State to data
    ...mapState([
      'shareableVersion'
    ])
  }
};
</script>

<style lang="scss">
  .subsection {
    width: 100%;
  }
</style>
