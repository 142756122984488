<template>
  <div class="sidebar-controls">
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="['sidebar-control', 'ra-gtm-event', {'active': active.label === item.label}]"
      :data-gtm-section="parent"
      :data-gtm-subsection="subsection"
      :data-gtm-label="item.shareLabel"
      @click="$emit('change-subsection', item); clickTracked($event)">
      <span>
        {{ item.label }}
      </span>
    </div>
  </div>
</template>

<script>
import { defaultMixins } from '@/common/mixins';

export default {
  name: 'Sidenav',
  mixins: [defaultMixins],
  props: {
    active: Object,
    // Array of Sections
    items: Array,
    parent: String,
    subsection: String
  },
  emits: ['change-subsection']
};
</script>

<style lang="scss">
  @import '@/scss/components/sideNav.scss';
</style>
