<template>
  <div class="share-button">
    <input
      type="checkbox"
      class="js-share-button"
      :id="`share-button${hash}`"
      :data-hash="hash"
      @click="$emit('share-this', {
        data: {
          hash,
          shareLabel: shareLabel,
          shareLink: shareLink
        },
        checked: $event.target.checked
      })" >
    <label :for="`share-button${hash}`">
      <span class="input">
        <svg width="12px" height="10px">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </svg>
      </span>
      <span class="label">
        {{ $t('buttons.share') }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ShareButton',
  emits: ['share-this'],
  props: {
    hash: String,
    shareLabel: String,
    shareLink: String
  }
};
</script>

<style lang="scss">
  @import '@/scss/components/ui/shareButton.scss';
</style>
