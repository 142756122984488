<template>
  <div>
    <!-- eslint-disable max-len -->
    <form id="forgot-password" @submit.prevent="submitForm">
      <p>
        {{ $t('login.forgot-password.intro') }}
      </p>
      <div class="form-group text-left">
        <label for="findEmail">
          {{ $t('login.common.username') }}
        </label>
        <input
          class="form-control"
          type="email"
          :placeholder="$t('login.common.placeholder.email')"
          required
          v-model="email">
      </div>
      <button
        class="cta mx-auto my-2"
        type="submit">
        <template v-if="isLoading">
          {{ $t("common.actions.loading") }}&nbsp;
          <i class="fa fa-spinner fa-pulse"></i>
        </template>
        <template v-else>
          {{ $t('login.common.submit') }}
        </template>
      </button>
    </form>
    <div class="regSuccess" v-if="successMsg">
      <p v-show="successMsg === 'success'">
        {{ $t("login.forgot-password.success-1") }}
        <span class="regemail">
          {{ email }}
        </span>
        {{ $t("login.forgot-password.success-2") }}
      </p>
      <p v-show="successMsg === 'nouser'">
        {{ $t("login.forgot-password.no-user-1") }} {{ email }} {{ $t("login.forgot-password.no-user-2") }}
      </p>
      <p v-show="successMsg === 'error'">
        {{ $t("login.create-account.error") }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import 'parsleyjs';

import * as endpoints from '@/common/endpoints';
import { loginMixins } from '@/common/mixins';

export default {
  name: 'Reset Password',
  mixins: [loginMixins],
  data() {
    return {
      email: '',
      successMsg: null,
      isLoading: false
    };
  },
  mounted() {
    this.parsleyTheForm('#forgot-password');
  },
  onBeforeUnmount() {
    $('#forgot-password').parsley().reset();
  },
  methods: {
    submitForm() {
      $('#forgot-password').parsley().on('form:submit', () => {
        this.isLoading = true;
        axios.post(
          `${endpoints.BASEAPIURL}mailfunctions${endpoints.EXT}`,
          {
            action: 'forgotpassword',
            email: this.email
          }
        )
          .then((response) => {
            const json = response.data;
            this.isLoading = false;

            if (json.reply === 1) {
              this.successMsg = 'success';
            } else if (json.message.includes('Nonuser')) {
              this.successMsg = 'nouser';
            } else {
              this.successMsg = 'error';
            }
          })
          .catch((error) => {
            console.error(error);
          });

        return false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/login.scss';
</style>
