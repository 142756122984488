<template>
  <div>
    <div :class="['virtual-tour__section', {'backdrop': !bg}, customClass]">
      <!-- eslint-disable max-len -->
      <div class="custom-bg js-custom-tour-bg" v-if="bg">
        <img :src="`${cmsURL}assets/${bg}`" />
      </div>

      <div class="virtual-tour__title text-center">
        <h2 class="title js-title">
          {{ data.headline }}
        </h2>
        <h5 class="title title--small js-subtitle">
          {{ data.description }}
        </h5>
      </div>

      <div class="inner-content" v-if="data.inner_points">
        <div class="container-fluid px-5">
          <div class="row flex-md-row-reverse">

            <div class="virtual-tour__points-holder">
              <div href="#virtual-tour__carousel" data-slide-to="0">
                <Hotspot
                  class="js-hotspot"
                  data-target="#modal-tour-card"
                  data-toggle="modal"
                  gtmLabel="Click Option A"
                  parent="Virtual Tour"
                  :position="data.inner_points[0].label_position"
                  :style="{
                    top: data.inner_points[0].position_top,
                    left: data.inner_points[0].position_left
                  }"
                  subSection="Unwinder"
                  :title="data.inner_points[0].point_label"
                  @click="clickTracked($event)">
                  {{ data.inner_points[0].point_letter }}
                </Hotspot>
              </div>
            </div>

            <div class="col-md-3">
              <h5 class="virtual-tour__add-ops-title js-box-title">
                {{ $t('virtual-tour.additional-options') }}
              </h5>

              <a
                class="virtual-tour__add-ops mb-2 ra-gtm-event js-box"
                data-slide-to="1"
                href="#virtual-tour__carousel">
                <div
                  data-gtm-section="Virtual Tour"
                  data-gtm-subsection="Unwinder"
                  data-gtm-label="Click Option B"
                  data-target="#modal-tour-card"
                  data-toggle="modal"
                  href="javascript:void(0);"
                  role="button"
                  @click="clickTracked($event)">
                  <div class="img-holder">
                    <!-- <pre style="color:white;">{{data.inner_points}}</pre> -->
                    <img
                      :alt="data.inner_points[1].media_caption"
                      class="img-fluid object-fit-cover-center"
                      :src="data.inner_points[1].media_items[0].source" />
                  </div>
                  <div class="add-ops__text d-flex justify-content-between align-items-center p-2">
                    {{ data.inner_points[1].point_letter }}. {{ data.inner_points[1].point_label }}
                    <svg version="1.1" id="Layer_1" x="0px" y="0px"
                        viewBox="0 0 8 13">
                      <polygon class="st0" points="1.2,12.8 7.5,6.5 1.2,0.2 0,1.5 5,6.5 0,11.5 "/>
                    </svg>
                  </div>
                </div>
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Carousel Bootstrap -->
    <Modal idModal="modal-tour-card">
      <div
        class="carousel slide js-carousel"
        id="virtual-tour__carousel"
        data-interval="false"
        data-ride="carousel"
        data-wrap="false" >
        <div class="carousel-inner">
          <div
            v-for="(item, index) in data.inner_points"
            :class="['carousel-item ',
            {'active': index === 0}]"
            :key="index">
            <Media
              :src="item.media_items[0].source"
              :title="item.media_caption"
              :type="item.media_type">
              <template v-slot:content>
                <div class="content" v-html="item.media_content"></div>
              </template>
            </Media>
          </div>
        </div>

        <a
          class="carousel-control-prev js-prev"
          data-slide="prev"
          href="#virtual-tour__carousel"
          role="button">
          <span class="icon">
            <svg width="17px" height="17px" viewBox="0 0 17 17" >
              <polygon class="st0" points="8.6,16.7 0.3,8.4 8.6,0 10.1,1.5 4.3,7.3 17,7.3 17,9.4 4.3,9.4 10.1,15.2 "/>
            </svg>
          </span>
          <span class="page-name"></span>
          <span class="sr-only">Previous</span>
        </a>

        <a
          class="carousel-control-next js-next active"
          data-slide="next"
          href="#virtual-tour__carousel"
          role="button">
          <span class="icon">
            <svg width="17px" height="17px" viewBox="0 0 17 17">
              <polygon class="st0" points="8.6,0 17,8.4 8.6,16.7 7.2,15.2 13,9.4 0.3,9.4 0.3,7.3 13,7.3 7.2,1.5 "/>
            </svg>
          </span>
          <span class="page-name"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </Modal>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import $ from 'jquery';
import { gsap } from 'gsap';

import * as endpoints from '@/common/endpoints';
import { defaultMixins } from '@/common/mixins';
import { showElement } from '@/common/animations';

import Hotspot from '@/components/ui/VirtualTourPoint.vue';
import Media from '@/components/ui/Media.vue';
import Modal from '@/components/modal/Modal.vue';

export default {
  name: 'TourCard',
  mixins: [defaultMixins],
  components: {
    Hotspot,
    Media,
    Modal
  },
  props: {
    bg: String,
    data: Object,
    customClass: String
  },
  data() {
    return {
      cmsURL: endpoints.CMS
    };
  },
  mounted() {
    this.hideMainTabs();

    gsap.timeline()
      .add(showElement('.js-title', 'top'))
      .add(showElement('.js-subtitle', 'top'))
      .add(showElement('.js-box-title', 'top'), '<')
      .add(showElement('.js-box', 'top'))
      .add(showElement('.js-hotspot', 'top'));

    // Open Modal Event Listener
    $('#modal-tour-01').on('show.bs.modal', () => {
      if ($('.js-carousel').find('.carousel-item.active').index() === 0) {
        $('.js-prev').removeClass('active');
      } else {
        $('.js-prev').addClass('active');
      }
    });

    // Modal Carousel event listener
    $('.js-carousel').on('slide.bs.carousel', (e) => {
      const thisCarousel = $('.js-carousel');
      const slidingItemsAsIndex = thisCarousel.find('.carousel-item').length - 1;

      // If first item, hide prev arrow
      if ($(e.relatedTarget).index() === 0) {
        $('.js-prev').removeClass('active');
      } else {
        $('.js-prev').addClass('active');
      }

      // If last item, hide next arrow
      if ($(e.relatedTarget).index() === slidingItemsAsIndex) {
        $('.js-next').removeClass('active');
      } else {
        $('.js-next').addClass('active');
      }
    });
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/virtual-tour/sections.scss';
</style>
