<template>
  <div class="package__cards-single-card">
    <div class="single-card__image">
      <img :src="card.image" alt="" />
    </div>
    <div class="single-card__content">
      <div class="single-card__content-container">
        <h4 class="single-card__content-title">
          {{ card.title }}
        </h4>
        <div
          class="single-card__content-description"
          v-html="card.description"></div>
      </div>
      <div class="single-card__content-button-container">
        <router-link
          class="cta ra-gtm-event"
          data-gtm-section="Plus Package"
          data-gtm-subsection=""
          data-gtm-label="Click Card"
          :to="currentPrinter === 3 ? card.cta : card.ctaT250"
          @click="clickTracked($event)">
          <span>
            {{ $t('buttons.learn-more') }}
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultMixins } from '@/common/mixins';

export default {
  mixins: [defaultMixins],
  props: {
    card: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/views/package/single-card.scss';
</style>
