<template>
  <Modal
    class="modal--login js-modal-login"
    idModal="loginModal"
    id="loginModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="loginModalLabel"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
    ref="loginModal">
    <!-- eslint-disable max-len -->

    <!-- Initial form -->
    <!-- <div class="wrapper-form login-part text-center" v-show="currentForm === 'login'"> -->
    <div
      class="wrapper-form login-part text-center"
      v-show="currentForm !== 'start' && currentForm !== 'intro-home'">
      <div class="logo">
        <img
          src="images/hp-blueprint-logo.svg"
          alt="" />
      </div>

      <!-- Login form -->
      <LoginForm
        v-if="currentForm === 'login'"
        @success="changeForm('start')" />
      <!-- // Login form -->

      <!-- Create Account form -->
      <CreateAccount v-if="currentForm === 'create'" />
      <!-- // Create Account form -->

      <!-- Forgot Password form -->
      <ResetPassword v-if="currentForm === 'password'" />
      <!-- // Forgot Password form -->

      <div class="login-actions">
        <a
          href="javascript:void(0);"
          v-show="currentForm !== 'login'"
          @click="changeForm('login')">
          {{ $t('login.common.login') }}
        </a>
        <a
          href="javascript:void(0);"
          v-show="currentForm !== 'password'"
          @click="changeForm('password')">
          {{ $t('login.common.forgot-password') }}
        </a>
        <a
          href="javascript:void(0);"
          v-show="currentForm !== 'create'"
          @click="changeForm('create')">
          {{ $t('login.common.new-account') }}
        </a>
      </div>
    </div>
    <!-- // Initial form -->

    <!-- New Presentation form -->
    <div
      class="wrapper-form presentation-part text-center"
      v-show="currentForm === 'start'">
      <div class="text-center">
        <h2 class="title">
          {{ $t('login.new-presentation.headline') }}
        </h2>
        <p>
          {{ $t('login.new-presentation.intro') }}
        </p>

        <a
          aria-label="Skip"
          class="cta cta--white skip-but ra-gtm-event"
          data-gtm-section="Presentation Login"
          data-gtm-label="Skip"
          href="javascript:void(0)"
          @click="closeModal('skip')">
          {{ $t('buttons.skip') }}
        </a>
      </div>

      <NewPresentation @close-modal="closeModal" />
    </div>
    <!-- // New Presentation form -->
  </Modal>
</template>

<script>
import $ from 'jquery';

import { mapState } from 'vuex';
import { defaultMixins } from '@/common/mixins';
import basil from '@/common/cookies';
import Modal from '@/components/modal/Modal.vue';

import LoginForm from '@/views/login/LoginForm.vue';
import CreateAccount from '@/views/login/CreateAccount.vue';
import ResetPassword from '@/views/login/ResetPassword.vue';
import NewPresentation from '@/views/login/NewPresentation.vue';

export default {
  name: 'Login',
  mixins: [defaultMixins],
  props: {
    currentForm: String
  },
  emits: ['change-form', 'skip', 'start-presentation'],
  components: {
    Modal,
    LoginForm,
    CreateAccount,
    ResetPassword,
    NewPresentation
  },
  mounted() {
    // If user is already logged
    if (basil.get('user')) {
      this.closeModal();
      this.$store.commit('fillSalesPersonData', basil.get('user'));
      if (basil.get('currentPrinter')) {
        this.$store.commit('changePrinter', basil.get('currentPrinter'));
      }
      this.$store.commit('login', 1);

      // Set locale
      basil.set('locale', basil.get('user').language);
      this.$i18n.locale = basil.get('user').language;

      // Trigger tracking function
      this.insertTrackingtoHPDB('all', '', 'login');
    } else {
      $('.js-modal-login').modal('show');
    }
  },
  methods: {
    changeForm(payload) {
      this.$emit('change-form', payload);
    },
    closeModal(skip) {
      if (skip) {
        this.$emit('skip');
      } else {
        this.$emit('start-presentation');
      }
      $('.js-modal-login').modal('hide');
    }
  },
  computed: {
    // Maps State to data
    ...mapState(['loggedin', 'shareableVersion'])
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/views/login.scss';
</style>
