<template>
  <div
    :class="['media-wrapper',
    {'media-wrapper--video': type === 'video'}]"
    ref="wrapper">
    <div class="media-wrapper__title"
      v-if="title && !isPlaying">
      <h4 class="title title--medium d-flex">
        <span v-html="title"></span>
        <span v-if="!!$slots.content" class="collapse-icon ml-2"></span>
      </h4>
      <slot name="content"></slot>
    </div>
    <template v-if="type === 'video'">
      <PlayButton
        v-if="!isPlaying"
        :data-gtm-section="dataGtmSection"
        :data-gtm-subsection="dataGtmSubsection"
        :data-gtm-label="dataGtmLabel"
        @click="playVideo" />

      <video
        class="video-js"
        width="100%"
        height="100%"
        ref="videoPlayer"
        :src="src"></video>
    </template>
    <template v-if="type === 'image'">
      <img :src="src" :alt="title">
    </template>
    <slot name="note"></slot>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'videojs-youtube/dist/Youtube.min';
import 'video.js/dist/video-js.css';

import PlayButton from './PlayButton.vue';

export default {
  name: 'MediaItem',
  components: {
    PlayButton
  },
  props: {
    dataGtmLabel: {
      type: String
    },
    dataGtmSection: {
      type: String
    },
    dataGtmSubsection: {
      type: String
    },
    note: {
      type: String
    },
    poster: {
      type: String
    },
    src: {
      type: String
    },
    title: {
      type: String
    },
    type: {
      type: String,
      default: 'video'
    }
  },
  data() {
    return {
      player: null,
      isPlaying: false
    };
  },
  mounted() {
    if (this.type === 'video') {
      this.player = videojs(this.$refs.videoPlayer,
        {
          autoplay: false,
          controls: true,
          techOrder: (this.src.indexOf('mp4') > 0) ? ['html5'] : ['youtube'],
          youtube: {
            ytControls: 2
          },
          sources: [{
            type: (this.src.indexOf('mp4') > 0) ? 'video/mp4' : 'video/youtube',
            src: this.src
          }]
        });

      this.updatePoster();

      this.player.on('pause', () => {
        // Bug when click on progress bar triggers the pause event
        setTimeout(() => {
          if (this.player.paused()) this.pauseVideo();
        }, 500);
      });
    }
  },
  updated() {
    this.updatePoster();
  },
  methods: {
    playVideo() {
      const thisVideo = this.player;
      const thisPlayer = this.$refs.wrapper;

      if (!this.isPlaying) {
        thisVideo.play();
        thisVideo.controls(true);
        thisPlayer.classList.add('playing');

        this.isPlaying = true;
      }
    },
    pauseVideo() {
      this.player.pause();
      this.player.controls(false);
      this.$refs.wrapper.classList.remove('playing');
      this.isPlaying = false;
    },
    updatePoster() {
      if (this.poster) {
        this.player.poster(this.poster);
      } else {
        this.player.poster('');
      }
    }
  }
};
</script>

<style lang="scss">
  @import '@/scss/components/ui/media.scss';
</style>
