<template>
  <div class="about-today">
    <div class="row justify-content-center">
      <div class="col-md-11">
        <img :src="data.hp_today_content" :alt="data.hp_today_headline">
        <img :src="data.hp_today_content_mobile" class="mobile" :alt="data.hp_today_headline">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HPToday',
  props: {
    data: Object
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/about/hp-today.scss';
</style>
