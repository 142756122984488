<template>
  <div class="tech-section--ink" v-if="info">
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ getData.title }}
        </h2>
      </div>

      <div class="section__content">
        <div class="row px-5 align-items-center">
          <div class="col-md-4">
            <h4 class="title title--medium title--blue">
              {{ getData.intro_title }}
            </h4>
            <br>
            <div v-html="getData.intro_content"></div>
          </div>

          <div
            class="col-md-4"
            v-for="(item, index) in getData.content"
            :key="index">
            <div class="brilliant-ink js-brilliant-ink" data-toggle="modal"
              :data-target="`#brilliant-ink-${index}`">
              <div class="brilliant-ink__img">
                <img :src="item.poster" alt="">

                <PlayButton
                  data-gtm-section="Technology"
                  data-gtm-subsection="HP Brilliant Ink"
                  data-gtm-label="Play Video" />
              </div>

              <div class="brilliant-ink__text">
                <h3>
                  {{ item.name }}
                </h3>
              </div>
            </div>
          </div>

          <div class="col-md-4" v-if="getData.additional_title || getData.additional_content">
            <h4 class="title title--medium title--blue">
              {{ getData.additional_title }}
            </h4>
            <br>
            <div v-html="getData.additional_content"></div>
          </div>

        </div>
      </div>
    </div>

    <Modal
      v-for="(item, index) in getData.content"
      :key="index"
      :id-modal="`brilliant-ink-${index}`">
      <Media
        data-gtm-section="Technology"
        data-gtm-subsection="Hp Brilliant Ink"
        :data-gtm-label="`${item.name} Video`"
        :src="item.video"
        :title="item.name" />
    </Modal>

    <Modal id-modal="brilliant-ink-video">
      <Media
        data-gtm-section="Technology"
        data-gtm-subsection="Hp Brilliant Ink"
        data-gtm-label="Brilliant Ink Video"
        src="videos/technology/brilliant-ink.mp4"
        title="Brilliant Ink" />
    </Modal>

    <ShareButton
      v-if="getData.shareable_pdf"
      :share-link="`${cmsURL}assets/${getData.shareable_pdf}`"
      :share-label="`${$t('main-nav.technology')} - ${$t('technology.submenu.brilliant-ink')} ${printerName}`"
      :hash="hashify(`${$t('main-nav.technology')} - ${$t('technology.submenu.brilliant-ink')} ${printerName}`)"
      @share-this="shareThis" />
  </div>
</template>

<script>
import axios from 'axios';
import { gsap } from 'gsap';

import * as endpoints from '@/common/endpoints';
import { defaultMixins } from '@/common/mixins';
import { showElement } from '@/common/animations';

import Modal from '@/components/modal/Modal.vue';
import PlayButton from '@/components/ui/PlayButton.vue';
import Media from '@/components/ui/Media.vue';
import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'A50Ink',
  mixins: [defaultMixins],
  components: {
    PlayButton,
    Modal,
    Media,
    ShareButton
  },
  data() {
    return {
      info: null
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/technology_brilliant_ink/2`, {
      params: {
        fields: '*,translations.*,printer.printers_id'
      }
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1'
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    gsap.timeline()
      .add(showElement('.js-brilliant-ink', 'center', {
        stagger: 0.5
      }))
      .delay(0.5);

    this.showMainTabs();
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/brilliantInk.scss';
</style>
