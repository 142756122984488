<template>
  <div class="content-about-presses">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <Media
          data-gtm-section="About"
          data-gtm-subsection="HP PageWide Web Presses"
          data-gtm-label="Watch HP PageWide Web Presses Video"
          poster="https://admin-pagewide.hp-blueprint.com/assets/9302f3f7-3633-4d6a-be2e-9cc0fc61adef"
          src="videos/HP_PageWide_Advantage_Intro.mp4" />

        <div class="row no-gutters">
          <div
            class="col-sm-6 col-lg-3"
            v-for="(item, index) in data.icons"
            :key="index">
            <div :class="['presses-icon',
              {'presses-icon--right': index % 2 !== 0},
              {'presses-icon--last': index === data.icons.length - 1}]">
              <img :src="item.icon" :alt="item.caption" class="icon">
              <p>
                {{ item.caption }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Media from '../../components/ui/Media.vue';

export default {
  name: 'Presses',
  props: {
    data: Object
  },
  components: {
    Media
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/about/presses.scss';
</style>
