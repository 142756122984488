<template>
  <div class="sub-nav">
    <div class="container-xl">
      <router-link
        exact-active-class="active"
        v-for="(link, index) in items" :key="index"
        class="ra-gtm-event"
        :data-gtm-section="parent"
        :data-gtm-subsection="link.shareLabel"
        data-gtm-label="Click Navigation"
        :to="`/${slugify(parent)}/${link.url}`"
        @click="closeSidebar(); clickTracked($event)">
        {{ link.label }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { defaultMixins, filters } from '@/common/mixins';

export default {
  name: 'Subnav',
  mixins: [defaultMixins, filters],
  props: {
    active: Object,
    // Array of labels
    items: Array,
    parent: String
  },
  methods: {
    closeSidebar() {
      this.$root.isSidebarOpen = false;
    }
  }
};
</script>

<style lang="scss">
  @import '@/scss/components/subNav.scss';
</style>
