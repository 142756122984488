<template>
  <div class="section__content--upgrade" v-if="info">
    <!-- eslint-disable max-len -->
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ $t('upgradability.submenu.path') }}
        </h2>
        <span v-if="!shareableVersion" class="reset-timeline" @click="resetTimeline">
          {{ $t('upgradability.reset-timeline') }}
        </span>
      </div>

      <div class="section__content section__content--upgrade">
        <div
          class="upgrade-timeline js-timeline"
          :class="timelineClass"
          ref="timeline">
          <div class="row align-items-end justify-content-around">
              <div
                v-for="(item, index) in getData.years"
                class="single-year"
                :class="[`js-year${index + 1}`, {'active': index === 0}]"
                :key="index"
                :ref="`year${index + 1}`">
                <div
                  class="single-year__detail"
                  :class="`js-yearDetail${index + 1}`"
                  :ref="`yearDetail${index + 1}`">
                  <div
                    class="featured"
                    v-for="(item, index) in item.top_features"
                    :key="index">
                    <p>
                        {{ item.feature }}
                    </p>
                  </div>
                  <div
                    v-if="item.printer"
                    class="header">
                    <h4>
                      {{ item.printer }}
                    </h4>
                  </div>
                  <div class="content">
                    <div
                      v-for="(item, index) in item.features"
                      :key="index"
                      v-html="item.feature"></div>
                  </div>
                </div>
                <div
                  class="single-year__intro"
                  :class="`js-yearIntro${index + 1}`"
                  :ref="`yearIntro${index + 1}`">
                  <div
                      class="single-year__pin active ra-gtm-event"
                      :class="`js-pin${index + 1}`"
                      :ref="`pin${index + 1}`"
                      :data-text="$t('upgradability.upgrade')"
                      data-gtm-section="Upgradability"
                      data-gtm-subsection="Upgradability Path"
                      :data-gtm-label="`Upgrade Year ${item.year}`"
                      @click="upgradeTimeline(index + 1, $event.target); clickTracked($event)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21"
                        viewBox="0 0 21 21">
                        <path
                          d="M10.5,21,21,10.5,10.5,0,8.645,1.855,15.97,9.211H0v2.578H15.97L8.645,19.145Z"
                          fill="#0072ad" />
                      </svg>
                  </div>
                  <h5>
                    {{ item.year }}
                    <span>
                      {{ item.label_upgrades }}
                    </span>
                  </h5>
                  <div
                    v-if="item.upgrades"
                    :class="['upgrades', 'text-center', {'inline': item.upgrades.length > 4}]">
                    <span
                      v-for="(item, index) in item.upgrades"
                      :key="index">
                      {{ item.upgrade }}
                    </span>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="timeline__line timeline__line--upgrade" ref="wrap-line">
          <div class="line-progress" ref="progressLine"></div>
        </div>
      </div>
    </div>

    <ShareButton
      v-if="!shareableVersion && getData.shareable_pdf"
      :hash="hashify(`${$t('main-nav.upgradability')} - ${$t('upgradability.submenu.path')} ${printerName}`)"
      :shareLabel="`${$t('main-nav.upgradability')} - ${$t('upgradability.submenu.path')} ${printerName}`"
      :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
      @share-this="shareThis" />
  </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import { gsap } from 'gsap';

import * as endpoints from '@/common/endpoints';
import { bounceIn, bounceOut } from '@/common/animations';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'UpgradabilityPath',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton
  },
  data() {
    return {
      info: null,
      totalYears: 0
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/upgradability_path`, {
      params: {
        fields: '*,translations.*,printer.printers_id'
      }
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1'
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.hideMainTabs();
  },
  updated() {
    this.totalYears = this.getData.years.length;
    this.resetTimeline();
    this.initTimeline();
    this.setLineInitialPosition();
  },
  methods: {
    initTimeline() {
      if (this.shareableVersion) {
        const shareableSingleYears = document.querySelectorAll('.single-year');
        const shareableSingleYearPin = document.querySelectorAll('.single-year__pin');

        shareableSingleYearPin.forEach((element) => { element.classList.add('hide'); });
        shareableSingleYears.forEach((element) => { element.classList.add('active'); });

        const shareableitems = this.$refs;
        gsap.timeline().to(shareableitems.progressLine, {
          width: '100%',
          duration: 0.3,
          ease: 'linear'
        });
      } else {
        bounceIn(this.$refs.yearDetail1);
        bounceIn(this.$refs.pin2);
        $('.js-yearIntro1').addClass('active');
      }
    },
    setLineInitialPosition() {
      const bodyRect = $('.js-timeline').offset();
      const elemRect = $('.js-yearIntro1').offset();
      const offset = elemRect.top - bodyRect.top;

      this.$refs['wrap-line'].style.top = `${offset - 13}px`;
    },
    setLineProgress(step) {
      const bodyRect = $('.js-timeline').offset();
      const targetYear = $(`.js-yearIntro${step}`).offset();
      const progress = (targetYear.left - bodyRect.left) + $(`.js-yearIntro${step}`).width() / 2;

      return progress;
    },
    resetTimeline() {
      // Hide all elements but the first one
      for (let index = 1; index < this.totalYears; index += 1) {
        bounceOut(this.$refs[`yearDetail${index + 1}`]);
        bounceOut(this.$refs[`pin${index + 1}`]);
        $(`.js-year${index + 1}`).removeClass('active');
      }

      gsap.to(this.$refs.progressLine, {
        width: '20px',
        duration: 0.3,
        ease: 'linear'
      })
        .then(() => this.initTimeline());
    },
    upgradeTimeline(year, clickedPin) {
      const items = this.$refs;

      // Remove overflow hidden
      items.timeline.classList.add('active');

      bounceOut(clickedPin)
        .add(bounceIn(items[`yearDetail${year}`]), '-=0.3')
        .call(() => $(`.js-yearIntro${year}`).addClass('active'))
        .add(() => {
          gsap.timeline()
            .add(bounceIn(items[`pin${year + 1}`]))
            .to(items.progressLine, {
              width: () => `${this.setLineProgress(year)}px`,
              duration: 0.3,
              ease: 'linear'
            }, '<');

          clickedPin.parentNode.parentNode.classList.add('active');
        }, '<');
    }
  },
  computed: {
    timelineClass() {
      const suffix = {
        1: 't250',
        2: 't490',
        3: 'a2200'
      };

      return `upgrade-timeline--${suffix[this.currentPrinter]}`;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/upgradability/base.scss';
  @import '@/scss/views/upgradability/upgradabilityPath.scss';
</style>
