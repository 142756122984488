<template>
  <div class="overlay-loading">
    <svg version="1.0" width="64px" height="64px" viewBox="0 0 128 128" xml:space="preserve">
      <path d="M59.6 0h8v40h-8V0z"
        fill="#ffffff" fill-opacity="1"></path>
      <path d="M59.6 0h8v40h-8V0z"
        fill="#ffffff" fill-opacity="0.2"
        transform="rotate(30 64 64)"></path>
      <path d="M59.6 0h8v40h-8V0z"
        fill="#ffffff" fill-opacity="0.2"
        transform="rotate(60 64 64)"></path>
      <path d="M59.6 0h8v40h-8V0z"
        fill="#ffffff" fill-opacity="0.2"
        transform="rotate(90 64 64)"></path>
      <path d="M59.6 0h8v40h-8V0z"
        fill="#ffffff" fill-opacity="0.2"
        transform="rotate(120 64 64)"></path>
      <path d="M59.6 0h8v40h-8V0z"
        fill="#ffffff" fill-opacity="0.3"
        transform="rotate(150 64 64)"></path>
      <path d="M59.6 0h8v40h-8V0z"
        fill="#ffffff" fill-opacity="0.4"
        transform="rotate(180 64 64)"></path>
      <path d="M59.6 0h8v40h-8V0z"
        fill="#ffffff" fill-opacity="0.5"
        transform="rotate(210 64 64)"></path>
      <path d="M59.6 0h8v40h-8V0z"
        fill="#ffffff" fill-opacity="0.6"
        transform="rotate(240 64 64)"></path>
      <path d="M59.6 0h8v40h-8V0z"
        fill="#ffffff" fill-opacity="0.7"
        transform="rotate(270 64 64)"></path>
      <path d="M59.6 0h8v40h-8V0z"
        fill="#ffffff" fill-opacity="0.8"
        transform="rotate(300 64 64)"></path>
      <path d="M59.6 0h8v40h-8V0z"
        fill="#ffffff" fill-opacity="0.9"
        transform="rotate(330 64 64)"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'loading'
};
</script>

<style lang="scss">
  @import '@/scss/components/ui/loading.scss';
</style>
