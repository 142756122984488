<template>
  <div class="container-xl" v-if="info">
    <div class="section__title text-center">
      <h2 class="title">
        <template v-if="this.currentPrinter==3">
          {{ getData.headline }}
        </template>
        <template v-else>
          {{ $t('upgradability.submenu.video') }}
        </template>
      </h2>
    </div>

    <Media
      data-gtm-section="Upgradability"
      data-gtm-subsection="Upgradability Video"
      data-gtm-label="Watch Video"
      :poster="`${cmsURL}assets/${getDataByPrinter.video_poster}`"
      ref="video"
      :src="getDataByPrinter.video_url"
      :title="getData.video_title"
      @click.stop="clickTracked($event)"/>

    <ShareButton
      :hash="hashify(`Upgradability Video ${currentPrinter}`)"
      :shareLabel="`${$t('main-nav.upgradability')} - ${$t('upgradability.submenu.video')}`"
      :shareLink="`${baseURL}${getDataByPrinter.video_url}`"
      @share-this="shareThis" />
  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import Media from '@/components/ui/Media.vue';
import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'UpgradabilityVideo',
  mixins: [filters, defaultMixins],
  components: {
    Media,
    ShareButton
  },

  data() {
    return {
      baseURL: endpoints.BASEURL,
      info: null
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/upgradability_video`, {
      params: {
        fields: '*,translations.*,printer.printers_id'
      }
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1'
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.hideMainTabs();
  }
};
</script>
