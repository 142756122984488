<template>
  <div class="row justify-content-center">
    <div class="col-12 col-xl-10">
      <div class="growth-graphic">
        <svg viewBox="0 0 189 189" class="badge-500" :class="$i18n.locale">
          <circle class="st0" cx="94.5" cy="94.5" r="93.5"/>
          <circle class="st1" cx="94.5" cy="94.5" r="87.5"/>
          <template v-if="$i18n.locale === 'es'">
            <text transform="matrix(1 0 0 1 20 90.9219)" class="text text--number">
              {{ data.growth_number_of_pages }}
            </text>
          </template>
          <template v-else>
            <text transform="matrix(1 0 0 1 36.4551 90.9219)" class="text text--number">
              {{ data.growth_number_of_pages }}
            </text>
          </template>
          <text transform="matrix(1 0 0 1 49.7563 122.6592)">
            <template v-if="$i18n.locale === 'en'">
              <tspan
                x="0" y="0" class="text">
                {{ $t('about.hp-growth.pages-quantity') }}
              </tspan>
              <tspan
                x="8.4" y="26" class="text">
                {{ $t('about.hp-growth.pages-label') }}
              </tspan>
            </template>
            <template v-if="$i18n.locale === 'es'">
              <tspan
                x="-5" y="0" class="text">
                {{ $t('about.hp-growth.pages-quantity') }}
              </tspan>
              <tspan
                x="-15" y="26" class="text">
                {{ $t('about.hp-growth.pages-label') }}
              </tspan>
            </template>
            <template v-if="$i18n.locale === 'de'">
              <tspan
                x="-24" y="0" class="text">
                {{ $t('about.hp-growth.pages-quantity') }}
              </tspan>
              <tspan
                x="0" y="26" class="text">
                {{ $t('about.hp-growth.pages-label') }}
              </tspan>
            </template>
            <template v-if="$i18n.locale === 'fr'">
              <tspan
                x="-18" y="0" class="text">
                {{ $t('about.hp-growth.pages-quantity') }}
              </tspan>
              <tspan
                x="-12" y="26" class="text">
                {{ $t('about.hp-growth.pages-label') }}
              </tspan>
            </template>
          </text>
        </svg>

        <div class="growth-year">
          <span>2010</span>
          <div class="bar bar--2010">
            <p>
              0.5B
            </p>
          </div>
        </div>
        <div class="growth-year">
          <span>2011</span>
          <div class="bar bar--2011"></div>
        </div>
        <div class="growth-year">
          <span>2012</span>
          <div class="bar bar--2012"></div>
        </div>
        <div class="growth-year">
          <span>2013</span>
          <div class="bar bar--2013"></div>
        </div>
        <div class="growth-year">
          <span>2014</span>
          <div class="bar bar--2014"></div>
        </div>
        <div class="growth-year">
          <span>2015</span>
          <div class="bar bar--2015"></div>
        </div>
        <div class="growth-year">
          <span>2016</span>
          <div class="bar bar--2016"></div>
        </div>
        <div class="growth-year">
          <span>2017</span>
          <div class="bar bar--2017"></div>
        </div>
        <div class="bg">
          <span class="title">
            {{ data.graphic_headline }}
          </span>
          <span class="note">
            {{ data.graphic_label }}
          </span>
          <div class="growth-year">
            <span>2018</span>
            <div class="bar bar--2018"></div>
          </div>
          <div class="growth-year">
            <span>2019</span>
            <div class="bar bar--2019"></div>
          </div>
          <div class="growth-year">
            <span>2020</span>
            <div class="bar bar--2020"></div>
          </div>
          <div class="growth-year">
            <span>2021</span>
            <div class="bar bar--2021"></div>
          </div>
          <div class="growth-year">
            <span>2022</span>
            <div class="bar bar--2022"></div>
          </div>
          <div class="growth-year">
            <span>Feb 23</span>
            <div class="bar bar--2023"></div>
          </div>
        </div>
        <small class="pages-note">
          {{ $t('about.hp-growth.pages-convention') }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Growth',
  props: {
    data: Object
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/about/growth.scss';
</style>
