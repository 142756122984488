<template>
  <div v-if="info">
    <!-- eslint-disable max-len  -->
      <div  v-if="!shareableVersion" class="container-xl">
        <div class="section__title text-center">
          <h2 class="title">
            {{ $t('partner-solutions.submenu.workflow') }}
          </h2>
        </div>

        <Subsection
          gtmSubsection="Workflow"
          parent="Partner Solutions"
          :currentComponent="subSections[currentSubsection]"
          :subSections="subSections"
          @click-subnav="changeTab" >
            <component
              :data="getData"
              :is="subSections[currentSubsection].component" />
        </Subsection>
      </div>
      <div v-else>
        <div class="section__title text-center">
          <h2 class="title">
            {{ $t('partner-solutions.submenu.workflow') }}
          </h2>
        </div>
        <div
          v-for="item in subSections"
          :key="item.id"
          class="container-xl">

          <Subsection
            gtmSubsection="Workflow"
            parent="Partner Solutions"
            :currentComponent="subSections[item.id]"
            :subSections="subSections"
            :isShareable="true"
            @click-subnav="changeTab">
            <component
              :data="getData"
              :is="subSections[item.id].component" />
          </Subsection>
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>

    <ShareButton
      v-if="!shareableVersion && getData.shareable_pdf"
      :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
      :shareLabel="`${$t('main-nav.partner-solutions')} - ${$t('partner-solutions.submenu.workflow')} ${printerName}`"
      :hash="hashify(`${$t('main-nav.partner-solutions')} - ${$t('partner-solutions.submenu.workflow')} ${printerName}`)"
      @share-this="shareThis" />

  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';
import Subsection from '@/layout/SubsectionNavLeft.vue';

import Standard from './workflow/Standard.vue';
import SiteFlow from './workflow/SiteFlow.vue';
import AddOns from './workflow/AddOns.vue';
import Partners from './workflow/Partners.vue';

export default {
  name: 'Workflow',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
    Subsection,
    Standard,
    SiteFlow,
    AddOns,
    Partners
  },
  data() {
    return {
      currentSubsection: 0,
      info: null
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/partner_solutions_workflow`, {
      params: {
        fields: '*,translations.*,printer.printers_id'
      }
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1'
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
  methods: {
    changeTab(payload) {
      this.currentSubsection = payload.id;
    }
  },
  computed: {
    /**
     * The items in the array MUST match the components name files
     * these values are trimmed and passed as components names.
     */
    subSections() {
      return [
        {
          component: 'Standard',
          id: 0,
          label: this.$i18n.t('partner-solutions.workflow.standard'),
          shareLabel: 'Click Standard with Press'
        },
        {
          component: 'SiteFlow',
          id: 1,
          label: this.$i18n.t('partner-solutions.workflow.site-flow'),
          shareLabel: 'Click Site Flow'
        },
        {
          component: 'AddOns',
          id: 2,
          label: this.$i18n.t('partner-solutions.workflow.addons'),
          shareLabel: 'Click Software & Hardware Add Ons'
        },
        {
          component: 'Partners',
          id: 3,
          label: this.$i18n.t('partner-solutions.workflow.partners'),
          shareLabel: 'Click SmartStream Partners'
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
  .wrapper-sub {
    display: flex;
    align-items: flex-start;
  }
</style>
