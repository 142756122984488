<template>
  <!-- eslint-disable max-len  -->
  <div class="package">
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ $t('plus-package.headline') }}<br />
          <strong>{{ $t('plus-package.subheadline') }}</strong>
        </h2>
      </div>
      <div class="section__content">
        <div class="package__cards text-center">
          <SingleCard
            v-for="(card, index) in cards"
            :key="index"
            :card="card" />
        </div>
      </div>
      <ShareButton
        v-if="!shareableVersion && shareLink"
        :shareLabel="`${$t('main-nav.package')} - ${printerName}`"
        :shareLink="shareLink"
        :hash="hashify(`${$t('main-nav.package')} - ${printerName}`)"
        @share-this="shareThis" />
    </div>
  </div>
</template>

<script>
import { filters, defaultMixins } from '@/common/mixins';

import SingleCard from '@/views/package/SingleCard.vue';
import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'Package',
  mixins: [filters, defaultMixins],
  components: {
    SingleCard,
    ShareButton
  },
  data() {
    return {
      shareLink: 'https://pagewide.hp-blueprint.com/shareable/plus-package.pdf'
    };
  },
  computed: {
    cards() {
      return [
        {
          image: 'images/package/performance-economy-color.png',
          title: this.$t('plus-package.card-1.headline'),
          description: this.$t('plus-package.card-1.description'),
          cta: '/technology/print-modes',
          ctaT250: '/technology/print-modes'
        },
        {
          image: 'images/package/smart-workcell-controller.png',
          title: this.$t('plus-package.card-2.headline'),
          description: this.$t('plus-package.card-2.description'),
          cta: '/virtual-tour/11',
          ctaT250: '/virtual-tour/9'
        },
        {
          image: 'images/package/on-press-color.png',
          title: this.$t('plus-package.card-3.headline'),
          description: this.$t('plus-package.card-3.description'),
          cta: '/virtual-tour/8',
          ctaT250: '/virtual-tour/8'
        },
        {
          image: 'images/package/extended-print-width.png',
          title: this.$t('plus-package.card-4.headline'),
          description: this.$t('plus-package.card-4.description'),
          cta: '/virtual-tour/11',
          ctaT250: '/virtual-tour/9'
        }
      ];
    }
  },
  mounted() {
    this.hideMainTabs();
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/views/package.scss';
</style>
