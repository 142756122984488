<template>
  <div class="row">
    <div :class="itemClass">
      <div class="tower-printer">
        <img class="printer" :src="`${cmsURL}assets/${data.printer_image}`" :alt="data.printer_name" />
        <h3 class="title title--small">
          <template v-if="changeHeadline">
            {{ data.printer_name_micr }}
          </template>
          <template v-else>
            {{ data.printer_name }}
          </template>
        </h3>
      </div>
    </div>
    <template v-if="!shareableVersion">
      <div :class="itemClass">
        <div v-if="data.applications" class="carousel carousel-tower slide js-carousel" :id="`carousel-tower-${data.id}`" data-interval="false" data-ride="carousel" data-wrap="true">
          <ol class="carousel-indicators">
            <li :data-target="`#carousel-tower-${data.id}`" v-for="(item, idx) in data.applications" :key="idx" :data-slide-to="idx" :class="{ active: idx === 0 }"></li>
          </ol>

            <div class="carousel-inner">
              <div v-for="(item, idx) in data.applications" :key="idx" class="carousel-item" :class="{ active: idx === 0 }">
                <div class="single-tower">
                  <div class="row">
                    <div :class="carouselItemClass">
                      <div class="tower-card-img">
                        <img class="printer" :src="item.image" :alt="item.name" />
                      </div>
                    </div>
                    <div :class="carouselItemClass">
                      <div class="tower-card-text">
                        <p class="bold">
                          {{ item.name }}
                        </p>
                        <div v-html="item.content"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control carousel-control-prev js-prev" role="button" data-slide="prev" :href="`#carousel-tower-${data.id}`">
              <span class="icon">
                <svg viewBox="0 0 17 17">
                  <polygon class="st0" points="8.6,16.7 0.3,8.4 8.6,0 10.1,1.5 4.3,7.3 17,7.3 17,9.4 4.3,9.4 10.1,15.2 " />
                </svg>
              </span>
              <span class="page-name"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control carousel-control-next js-next active" role="button" data-slide="next" :href="`#carousel-tower-${data.id}`">
              <span class="icon">
                <svg viewBox="0 0 17 17">
                  <polygon class="st0" points="8.6,0 17,8.4 8.6,16.7 7.2,15.2 13,9.4 0.3,9.4 0.3,7.3 13,7.3 7.2,1.5 " />
                </svg>
              </span>
              <span class="page-name"></span>
              <span class="sr-only">Next</span>
            </a>
        </div>
      </div>
    </template>
    <template v-else>
      <div :class="itemClass">
        <div v-if="data.applications"  class="carousel-tower">
          <div class="carousel-inner">
            <div v-for="(item, idx) in data.applications" :key="idx" class="carousel-item shareable-carousel-item">
              <div class="single-tower">
                <div class="row">
                  <div :class="carouselItemClass">
                    <div class="tower-card-img">
                      <img class="printer" :src="item.image" :alt="item.name" />
                    </div>
                  </div>
                  <div :class="carouselItemClass">
                    <div class="tower-card-text">
                      <p class="bold">
                        {{ item.name }}
                      </p>
                      <div v-html="item.content"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <ShareButton
      v-if="!shareableVersion && data.shareable_pdf"
      :hash="hashify(`${$t('main-nav.scalability')} - ${$t('upgradability.submenu.tower')} ${data.printer_name} ${data.tab_name}`)"
      :shareLabel="`${$t('main-nav.scalability')} - ${$t('upgradability.submenu.tower')} - ${data.printer_name} (${data.tab_name})`"
      :shareLink="`${cmsURL}assets/${data.shareable_pdf}`"
      @share-this="shareThis" />
  </div>
</template>

<script>
import $ from 'jquery';
import { filters, defaultMixins } from '@/common/mixins';
import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'Tower',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton
  },
  data() {
    return {
      changeHeadline: false,
      shouldUpdateHeadline: true
    };
  },
  props: {
    layout: String,
    data: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    $('[id^="carousel-tower-"]').carousel();

    $('[id^="carousel-tower-"]').on('slide.bs.carousel', (event) => {
      if (this.shouldUpdateHeadline) {
        const slideIndex = $(event.relatedTarget).index();
        const slideData = this.data.applications[slideIndex];
        this.changeHeadline = slideData.micr;
      }
    });
  },
  beforeUpdate() {
    if (this.shouldUpdateHeadline) {
      $('[id^="carousel-tower-"]').carousel(0);
    }

    setTimeout(() => {
      this.shouldUpdateHeadline = true;
    }, 0);
  },
  computed: {
    itemClass() {
      return this.layout === 'top' ? 'col-12' : 'col-md-6';
    },
    carouselItemClass() {
      return this.layout === 'left' ? 'col-12' : 'col-md-6';
    }
  },
  watch: {
    changeHeadline() {
      this.shouldUpdateHeadline = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/views/upgradability/tower-of-production/tower.scss';
</style>
