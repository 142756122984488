<template>
  <div class="tij-architecture">
    <div class="tij-architecture__section tij-architecture__section--1">
      <img
        src="images/technology/hp-ink-cartidge.png"
        alt=""
        ref="img1" >
      <div
        class="hotspot js-hotspot hotspot--1"
        ref="spot1"></div>
      <img
        src="images/technology/arrow.svg"
        alt=""
        class="arrow js-arrow arrow--1"
        ref="arrow1" >
    </div>
    <div class="tij-architecture__section tij-architecture__section--2">
      <img
        src="images/technology/print-head.png"
        alt=""
        ref="img2" >
      <div
        class="hotspot js-hotspot hotspot--2"
        ref="spot2"></div>
      <img
        src="images/technology/arrow.svg"
        alt=""
        class="arrow js-arrow arrow--2"
        ref="arrow2" >
    </div>
    <div class="tij-architecture__section tij-architecture__section--3">
      <img
        :src="data.hdna_image"
        alt=""
        ref="img3">
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';

import { showElement } from '@/common/animations';

export default {
  name: 'HDNA',
  props: {
    data: Object
  },
  mounted() {
    gsap.timeline()
      .add(showElement(this.$refs.img1))
      .add(showElement(this.$refs.spot1), '+=0.15')
      .add(showElement(this.$refs.arrow1))
      .add(showElement(this.$refs.img2))
      .add(showElement(this.$refs.spot2), '+=0.15')
      .add(showElement(this.$refs.arrow2))
      .add(showElement(this.$refs.img3));
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/tij/hdna.scss';
</style>
