<template>
  <div
    v-if="info"
    class="intro">
    <div class="container-xl">
      <div class="row">
        <div class="next-steps-bg">
          <img src="images/technology/tech-specs-render.jpg" alt="">
        </div>
        <div class="offset-md-4 offset-lg-6 col-md-8 col-lg-6">
          <h2 class="title title--big">
            {{ getData.headline }}
          </h2>
          <br>
          <div class="section__content">
            <div class="next-steps-content" v-html="getData.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { defaultMixins } from '@/common/mixins';

export default {
  name: 'NextSteps',
  mixins: [defaultMixins],
  data() {
    return {
      info: null
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/next_steps`, {
      params: {
        fields: '*,translations.*,printer.printers_id'
      }
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/nextSteps.scss';
</style>
