<template>
  <div class="modal fade" :id="idModal" tabindex="-1" role="dialog" ref="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <button class="hamburger--collapse is-active close"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        @click="$emit('close-modal')">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>

      <div class="modal-content">
        <div class="container-xl">
          <div ref="mySlot">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Modal',
  props: {
    idModal: {
      required: true,
      type: String
    }
  },
  emits: ['close-modal'],
  mounted() {
    const modalContent = this.$refs.mySlot;
    const video = modalContent.getElementsByTagName('video');

    $(this.$refs.modal).on('hide.bs.modal', () => {
      if (video.length > 0) {
        video.forEach(($el) => {
          $el.pause();
        });
      }
    });
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/components/modal/modal.scss';
</style>
