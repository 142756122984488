<template>
  <div>
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ getData.headline }}
        </h2>
      </div>
      <div class="section__content">
        <div class="text" v-html="getData.description"></div>

        <div class="wrap-options">
          <div v-for="(option, idx) in getData.option" :key="idx" class="single-option">
            <div class="printer-title">
              <h3 class="title title--small">
                <template v-if="changeColHeadline[idx]">
                  {{ option.name_micr }}
                </template>
                <template v-else>
                  {{ option.name }}
                </template>
              </h3>
            </div>
            <div class="printer-container">
              <div class="tag--text" v-if="option.tag" v-html="option.tag"></div>
              <img class="printer" :src="option.printer_image" :alt="option.name" />
            </div>

            <div>
              <template v-if="changeColHeadline[idx]">
                <div class="text" v-html="option.content_micr"></div>
              </template>
              <template v-else>
                <div class="text" v-html="option.content"></div>
              </template>

              <div class="carousel slide js-carousel" :id="`carousel-${idx}-module`" data-interval="false" data-ride="carousel" data-wrap="true">
                <ol class="carousel-indicators" data-v-6e262f00="">
                  <li v-for="(img, id) in option.images" :key="id" :data-target="`carousel-${idx}-module`" :data-slide-to="idx" :class="id === 0 ? 'active' : ''"></li>
                </ol>
                <div class="carousel-inner">
                  <div v-for="(img, id) in option.images" :key="id" :data-image="id" class="carousel-item" :class="{ active: id === 0 }">
                    <img class="print-jobs-paper" :src="img.image" alt="" />
                  </div>
                </div>

                <a class="carousel-control carousel-control-prev js-prev" data-slide="prev" :href="`#carousel-${idx}-module`" role="button">
                  <span class="icon">
                    <svg viewBox="0 0 17 17">
                      <polygon class="st0" points="8.6,16.7 0.3,8.4 8.6,0 10.1,1.5 4.3,7.3 17,7.3 17,9.4 4.3,9.4 10.1,15.2 " />
                    </svg>
                  </span>
                  <span class="page-name"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control carousel-control-next js-next active" data-slide="next" :href="`#carousel-${idx}-module`" role="button">
                  <span class="icon">
                    <svg viewBox="0 0 17 17">
                      <polygon class="st0" points="8.6,0 17,8.4 8.6,16.7 7.2,15.2 13,9.4 0.3,9.4 0.3,7.3 13,7.3 7.2,1.5 " />
                    </svg>
                  </span>
                  <span class="page-name"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <ShareButton
      v-if="getData.shareable_pdf"
      :hash="hashify(`${$t('main-nav.technology')} - ${$t('technology.submenu.print-modes')} ${printModeName} ${printerName}`)"
      :shareLabel="`${$t('main-nav.technology')} - ${$t('technology.submenu.print-modes')} ${printModeName} ${printerName}`"
      :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
      @share-this="shareThis" /> -->
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import $ from 'jquery';
import axios from 'axios';
import { filters, defaultMixins } from '@/common/mixins';

import * as endpoints from '@/common/endpoints';
// import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'ConfigurationOptions',
  mixins: [filters, defaultMixins],
  components: {
    // ShareButton,
  },
  data() {
    return {
      info: null,
      changeColHeadline: { 0: true }
    };
  },
  created() {
    axios
      .get(
        `${endpoints.CMS}items/configuration_options`,
        {
          params: {
            fields: '*,translations.*,printer.printers_id'
          }
        },
        {
          'Cache-Control': 'no-cache',
          Expires: '-1'
        }
      )
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.hideMainTabs();

    setTimeout(() => {
      this.getData.option.map((col, idx) => {
        $(`#carousel-${idx}-module`).on('slide.bs.carousel', ({ to }) => {
          this.changeColHeadline[idx] = col.images[to].micr || false;
        });

        return true;
      });
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/views/upgradability/configurationOptions.scss';
</style>
