<template>
  <div class="main-card">
    <div class="row">
      <div class="col-sm-5">
        <div
          class="main-card__img"
          :style="{'background-image': `url(${cmsURL}assets/${data.image})`}">
          <div class="main-card__check">
            <input
              type="checkbox"
              class="js-share-button"
              :data-hash="hashify(normalizedLabel)"
              @change="$emit('share-card', {
                data: {
                  shareLabel: normalizedLabel,
                  shareLink: shareableLink,
                  hash: hashify(normalizedLabel)
                },
                checked: $event.target.checked
              })">
          </div>
        </div>
      </div>
      <div class="col-sm-7">
        <div class="main-card__text">
          <img class="logo" :src="`${cmsURL}assets/${data.logo}`" alt="">

          <h3 class="title title--small">
            {{ data.content.name }}
          </h3>

          <div>
            <div v-html="data.content.content"></div>

            <a class="link link--inline data-link ra-gtm-event"
              :href="data.website"
              :data-gtm-subsection="dataSubsection"
              :data-gtm-label="`${data.identifier} - Visit Website`"
              target="_blank" :data-gtm-section="dataSection"
              @click="clickTracked($event)">
              {{ $t('buttons.website') }}
            </a>
          </div>

          <Button
            v-if="data.brochure"
            class="cta--inline"
            data-toggle="modal"
            :data-target="targetModal"
            :data-gtm-section="dataSection"
            :data-gtm-subsection="dataSubsection"
            :data-gtm-label="`${data.identifier} - Download DataSheet`"
            @click="$emit('show-modal', idCard); clickTracked($event)" >
            {{ $t('buttons.data-sheet') }}
          </Button>

          <Button
            v-if="data.video"
            class="cta--inline"
            data-toggle="modal"
            :data-target="`#modal-options-video-${idCard}`"
            :data-gtm-section="dataSection"
            :data-gtm-subsection="dataSubsection"
            :data-gtm-label="`${data.identifier} - Watch Video`"
            @click="$emit('show-modal', idCard); clickTracked($event)" >
            {{ $t('buttons.watch-video') }}
            <svg aria-hidden="true" role="img" class="icon" viewBox="0 0 24 24">
              <path
                d="M10.8 15.9l4.67-3.5c.27-.2.27-.6 0-.8L10.8 8.1a.5.5 0 0 0-.8.4v7c0 .41.47.65.8.4zM12 2C6.48 2 2 6.48
                2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8z"
                fill="#ffffff"></path>
            </svg>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as endpoints from '@/common/endpoints';
import { defaultMixins, filters } from '@/common/mixins';
import Button from '@/components/ui/Button.vue';

export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    cardType: {
      type: String
    },
    featured: {
      type: Boolean
    },
    buttonText: {
      type: String
    },
    data: {
      required: true,
      type: Object
    },
    idCard: {
      type: Number
    },
    targetModal: {
      type: String
    },
    dataSection: {
      type: String
    },
    dataSubsection: {
      type: String
    },
    shareSection: {
      type: String
    },
    shareSubsection: {
      type: String
    }
  },
  emits: ['share-card'],
  components: {
    Button
  },
  mixins: [defaultMixins, filters],
  data() {
    return {
      baseURL: endpoints.BASEURL
    };
  },
  computed: {
    normalizedLabel() {
      const products = (this.data.content.product)
        ? ` (${this.data.content.product.replace(/<\/?[^>]+(>|$)/g, ' ')})`
        : '';
      const prefix = (this.shareSubsection) ? this.shareSubsection : this.shareSection;
      let name = null;

      if (!this.featured) {
        if (this.dataSection === 'Customer Stories') {
          name = this.data.content.title;
        } else {
          name = this.data.content.name;
        }
      } else {
        name = this.data.content.name;
      }

      return `${prefix} - ${name}${products}`;
    },
    shareableLink() {
      const link = (this.data.article_link) ? `${this.data.article_link}\n` : '';
      const website = (this.data.website) ? `${this.data.website}\n` : '';
      const brochure = (this.data.brochure) ? `${this.cmsURL}assets/${this.data.brochure}\n` : '';
      const pdf = (this.data.content.shareable_pdf) ? `${this.cmsURL}assets/${this.data.content.shareable_pdf}\n` : '';
      const fullstory = (this.data.content.fullstory_pdf) ? `${this.cmsURL}assets/${this.data.content.fullstory_pdf}\n` : '';

      let asset = '';
      let video = '';

      if (this.data.asset) {
        if (this.data.asset.indexOf('.mp4') >= 0) {
          asset = `${this.baseURL}${this.data.asset}\n`;
        } else if (this.data.asset.indexOf('pdf/') >= 0) {
          asset = `${this.baseURL}${this.data.asset}\n`;
        } else {
          asset = `${this.data.asset}\n`;
        }
      }

      if (this.data.asset_pdf) {
        asset = `${this.cmsURL}assets/${this.data.asset_pdf}\n`;
      }

      if (this.data.video) {
        video = (this.data.video.indexOf('.mp4') >= 0) ? `${this.baseURL}${this.data.video}\n` : `${this.data.video}\n`;
      }

      return `${pdf}${asset}${brochure}${video}${website}${link}${fullstory}`;
    }
  }
};

</script>

<style lang="scss" scoped>
  @import '@/scss/components/card/card.scss';
</style>
