<template>
  <div class="workflow-table">
    <Headers />

    <div class="row no-gutters">
      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div
          class="frame"
          v-for="(group, index) in data.partners_column_1"
          :key="index">
            <h6>
              {{ group.title }}
            </h6>
            <div class="frame__logos">
              <a
                v-for="(item, index) in group.logos"
                :key="index"
                :href="item.link"
                target="_blank">
                <img
                  :src="item.logo"
                  alt="">
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div
          class="frame"
          v-for="(group, index) in data.partners_column_2"
          :key="index">
            <h6>
              {{ group.title }}
            </h6>
            <div class="frame__logos">
              <a
                v-for="(item, index) in group.logos"
                :key="index"
                :href="item.link"
                target="_blank">
                <img
                  :src="item.logo"
                  alt="">
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div
          class="frame"
          v-for="(group, index) in data.partners_column_3"
          :key="index">
            <h6>
              {{ group.title }}
            </h6>
            <div class="frame__logos">
              <a
                v-for="(item, index) in group.logos"
                :key="index"
                :href="item.link"
                target="_blank">
                <img
                  :src="item.logo"
                  alt="">
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="workflow-table__body">
          <div
          class="frame"
          v-for="(group, index) in data.partners_column_4"
          :key="index">
            <h6>
              {{ group.title }}
            </h6>
            <div class="frame__logos">
              <a
                v-for="(item, index) in group.logos"
                :key="index"
                :href="item.link"
                target="_blank">
                <img
                  :src="item.logo"
                  alt="">
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div
          class="frame"
          v-for="(group, index) in data.partners_column_5"
          :key="index">
            <h6>
              {{ group.title }}
            </h6>
            <div class="frame__logos">
              <a
                v-for="(item, index) in group.logos"
                :key="index"
                :href="item.link"
                target="_blank">
                <img
                  :src="item.logo"
                  alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headers from './TableHeaders.vue';

export default {
  name: 'Partners',
  props: {
    data: Object
  },
  components: {
    Headers
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/partner-solutions/workflow.scss';
</style>
