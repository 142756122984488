<template>
  <header>
    <!-- eslint-disable max-len  -->
    <div class="d-flex container-fluid align-items-center justify-content-between">
      <router-link
        href="javascript:void(0)"
        class="brand-logo ra-gtm-event"
        data-gtm-section="All"
        data-gtm-subsection=""
        data-gtm-label="Click Logo - Restart Presentation"
        to="/">
        <svg width="56px" height="55px" viewBox="0 0 432 432" fill="#ffffff">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M216,432c-2.15,0-4.29-0.04-6.42-0.1l44.14-121.32h60.75c10.66,0,22.37-8.2,26.01-18.21l47.89-131.55c7.84-21.54-4.5-39.16-27.42-39.16h-84.28l-70.71,194.32h-0.03l-40.09,110.15C70.74,403.52,0,318.03,0,216 C0,116.92,66.72,33.42,157.68,7.97l-41.37,113.69h-0.01L47.54,310.58l45.78,0l58.48-160.53h34.4l-58.48,160.53l45.76,0.01 l54.5-149.77c7.84-21.54-4.5-39.16-27.39-39.16h-38.48L206.29,0.23C209.51,0.08,212.75,0,216,0c119.29,0,216,96.71,216,216C432,335.29,335.29,432,216,432zM346.69,149.94H312.3L264.13,282.1h34.4L346.69,149.94z" />
        </svg>
      </router-link>

      <div class="header__tabs js-header-tabs">
        <div :class="['single-tab', {'active': parseFloat(currentPrinter) === 3}]"
        @click="changePrinter(3)">
          <span>
            Advantage<br>2200
          </span>
        </div>
        <div :class="['single-tab', {'active': parseFloat(currentPrinter) === 1}]"
          @click="changePrinter(1)">
          <span>
            T250 HD
          </span>
        </div>
        <div :class="['single-tab', {'active': parseFloat(currentPrinter) === 2}]"
        @click="changePrinter(2)">
          <span>
            T400 Series
          </span>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="wrap-locales">
          <span
            data-toggle="collapse" href="#locales-list"
            role="button"
            aria-expanded="false" aria-controls="locales-list">
            <i class="fa fa-globe"></i>
            {{ uppercase($i18n.locale) }}
          </span>

          <ul class="dropdown collapse" id="locales-list">
            <li
              v-show="$i18n.locale !== 'en'">
              <a
                href="#locales-list"
                data-toggle="collapse"
                aria-controls="locales-list"
                @click="changeLocale('en', $event)">
                EN
              </a>
            </li>
            <li
              v-show="$i18n.locale !== 'es'">
              <a
                href="#locales-list"
                data-toggle="collapse"
                aria-controls="locales-list"
                @click="changeLocale('es', $event)">
                ES
              </a>
            </li>
            <li
              v-show="$i18n.locale !== 'de'">
              <a
                href="#locales-list"
                data-toggle="collapse"
                aria-controls="locales-list"
                @click="changeLocale('de', $event)">
                DE
              </a>
            </li>
            <li
              v-show="$i18n.locale !== 'fr'">
              <a
                href="#locales-list"
                data-toggle="collapse"
                aria-controls="locales-list"
                @click="changeLocale('fr', $event)">
                FR
              </a>
            </li>
          </ul>
        </div>

        <div class="share-toggler mr-3">
          <div class="share-toggler__count">
            {{ sharedList.length }}
          </div>
          <a class="d-block" data-toggle="collapse" href="#share-cart" role="button"
            aria-expanded="false" aria-controls="share-cart">
            <i class="fa fa-share-square-o"></i>
          </a>
          <div class="share-toggler__cart p-3 collapse" id="share-cart">
            <div class="text-white">
              <div class="f-light" v-if="sharedList.length > 0">
                {{ $t('common.share-headline') }}
              </div>
              <div class="user-email text-white" v-if="sharedList.length > 0 && companyContact.email">
                {{ companyContact.email }}
              </div>

              <template v-if="sharedList.length > 0">
                <div class="share-toggler__list mt-3">
                  <div v-for="(item, index) in sharedList"
                    :key="index"
                    class="share-toggler__list-item">
                    <span v-html="item.shareLabel"></span>
                    <i class="fa fa-trash js-remove-shared" @click="deleteSharedItem(item)"></i>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="mb-3">
                  {{ $t('common.share-headline-empty') }}
                </div>
              </template>

              <Button
                :class="{'disabled': sharedList.length < 1}"
                gtmSection="All"
                gtmSubsection=""
                gtmLabel="Click share"
                :href="emailURL"
                @click="shareTracking">
                {{ $t('buttons.share') }}
              </Button>
            </div>
          </div>
        </div>
        <button
          :class="['hamburger', 'hamburger--collapse', {'is-active': isSidebarOpen}]"
          type="button"
          aria-label="Menu"
          @click="$emit('toggle-sidebar')">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import { gsap } from 'gsap';

import { defaultMixins, filters } from '@/common/mixins';

import Button from './ui/Button.vue';

export default {
  name: 'MainHeader',
  mixins: [defaultMixins, filters],
  emits: ['toggle-sidebar'],
  components: {
    Button
  },
  props: {
    isSidebarOpen: {
      type: Boolean
    }
  },
  methods: {
    changeLocale(payload, e) {
      e.preventDefault();
      this.$root.$i18n.locale = payload;
    },
    changePrinter(payload) {
      this.$parent.closeShare();

      return gsap.timeline()
        .set('.js-overlay', {
          y: 0,
          height: 0
        })
        .to('.js-overlay', {
          height: '100%',
          ease: 'power4.in',
          duration: 0.2
        })
        .call(() => this.$store.commit('changePrinter', payload))
        .to('.js-overlay', {
          duration: 0.3,
          y: '100%',
          ease: 'power4.out'
        }, '+=0.2');
    },
    deleteSharedItem(item) {
      this.$store.commit('removeItem', item);
    }
  },
  computed: {
    // Maps State to data
    ...mapState([
      'companyName',
      'currentPrinter'
    ]),
    emailURL() {
      const formattedBody = `${this.$i18n.t('email.greeting')} ${this.companyContact.firstname},


${this.$i18n.t('email.body')}
${this.salesPerson.firstname} ${this.salesPerson.lastname}\n\n`;

      // Build List of links
      let itemsList = '';

      for (let index = 0; index < this.sharedList.length; index += 1) {
        itemsList += `${this.sharedList[index].shareLabel}:
${this.sharedList[index].shareLink}\n`;
      }

      return `mailto:${this.companyContact.email}?Subject=${this.$i18n.t('email.subject')}&body=${encodeURIComponent(formattedBody)} ${encodeURIComponent(itemsList)}`;
    }
  },
  mounted() {
    console.log(typeof this.currentPrinter);
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/scss/components/header.scss';
</style>
