<template>
<div v-if="!shareableVersion">
  <Overlay class="preloader" v-show="!hasPresentationStarted">
    <img src="images/hp-blueprint-logo.svg" alt="" />
  </Overlay>

  <Overlay />

  <div v-if="shareableVersion" class="footnote">
    Last Update: {{ new Date(Date.now()).toDateString() }}
  </div>

  <div :class="['main-wrap', { sidebar: isSidebarOpen }]">
    <MainHeader
      v-if="!shareableVersion"
      :isSidebarOpen="isSidebarOpen"
      @toggle-sidebar="toggleSidebar" />

    <MainNav v-if="!shareableVersion" :class="{ sidebar: isSidebarOpen }" />

    <div class="wrap-main-content">
      <div class="main-content__item" v-show="hasPresentationStarted">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component
              class="section"
              :sidebar="isSidebarOpen"
              :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>

  <MainSidebar
    :class="{ active: isSidebarOpen }"
    @new-presentation="startNewPresentation" />

  <Login
    v-show="!shareableVersion"
    :currentForm="currentLoginForm"
    @change-form="changeCurrentForm"
    @skip="startPresentation(true)"
    @start-presentation="startPresentation" />

  <div v-if="shareableVersion" class="footnote">
    Last Update: {{ new Date(Date.now()).toDateString() }}
  </div>
</div>
<div v-else>
    <div class="wrap-main-content">
        <div class="main-content__item">
          <div
          class="wrapper-form login-part text-center">
          <div class="logo shareablelogo">
            <img
              src="images/hp-blueprint-logo.svg"
              alt="" />
              <div class="footnote">
              Last Update: {{ new Date(Date.now()).toDateString() }}
            </div>
          </div>
        </div>
        <router-view v-slot="{ Component }">
            <component
              class="section"
              :sidebar="isSidebarOpen"
              :is="Component" />
        </router-view>
        <div class="footnote text-center">
          Copyright HP Pagewide Web Press BLUEPRINT<sup>©</sup> {{ currentYear }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './scss/_base.scss';

import { mapState } from 'vuex';
import { basilModal } from '@/common/cookies';
import getAllParameters from '@/common/functions';

import Overlay from '@/components/ui/Overlay.vue';

// Login Form
import Login from '@/views/Login.vue';

// Navigation Items
import MainHeader from '@/components/Header.vue';
import MainNav from '@/components/MainNav.vue';
import MainSidebar from '@/components/MainSidebar.vue';

export default {
  name: 'App',
  components: {
    Overlay,
    Login,
    MainHeader,
    MainNav,
    MainSidebar
  },
  data() {
    return {
      isSidebarOpen: false,
      hasPresentationStarted: false,
      currentLoginForm: 'login'
    };
  },
  methods: {
    changeCurrentForm(payload) {
      this.currentLoginForm = payload;
    },
    closeShare() {
      const toggler = document.getElementById('share-cart');
      toggler.classList.remove('show');
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    startPresentation(isBlank) {
      this.hasPresentationStarted = true;

      if (isBlank) {
        const blankData = {
          email: '',
          firstname: '',
          lastname: '',
          title: '',
          phone: '',
          team: []
        };

        this.$store.commit('fillCompanyData', blankData);
      }
    },
    startNewPresentation() {
      this.currentLoginForm = 'start';
      this.hasPresentationStarted = false;
    }
  },
  watch: {
    // eslint-disable-next-line
    '$i18n.locale': function () {
      basilModal.set('closedModal', false);
    }
  },
  mounted() {
    const allparams = getAllParameters();

    // Set clean version
    if (allparams.version && window.atob(allparams.version) === 'shareable') {
      this.$store.commit('setShareableVersion', true);
    }

    // Set languange
    if (allparams.lang) {
      this.$i18n.locale = allparams.lang;
    }

    // Set printer
    if (allparams.printer) {
      this.$store.commit('changePrinter', parseInt(allparams.printer, 10));
    }

    // Set slide
    if (allparams.slide) {
      this.$store.commit('changeSlide', parseInt(allparams.slide, 10));
    }
    if (this.shareableVersion) {
      const backdrops = document.querySelectorAll('.modal-backdrop');
      const modal = document.querySelectorAll('.modal-open');
      backdrops.forEach((element) => { element.classList.remove('show'); });
      modal.forEach((element) => { element.classList.remove('modal-open'); });
    }
  },
  computed: {
    // Maps State to data
    ...mapState([
      'shareableVersion'
    ]),
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style lang="scss">
.main-wrap {
  position: relative;
  right: 0;
  transition: all 0.3s ease;

  &.sidebar {
    right: 320px;
  }
}

.shareablelogo{
  width: 500px;
  margin: 20px auto;
}

.main-content__item {
  display: block;
  min-height: 100vh;

  .section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 175px 0 145px;
    min-height: 100vh;

    @media (min-height: 800px) {
      padding: 180px 0 150px;
    }

    &--top {
      align-items: flex-start;
      padding: 115px 0 145px;

      @media (min-height: 800px) {
        padding: 120px 0 150px;
      }
    }
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

// Shareable footnote
.footnote {
  padding: 1em;
  text-align: right;
  font-size: 12px;
  opacity: 0.75;
}
</style>
