<template>
  <div class="workflow-table">
    <Headers />

    <div class="row no-gutters">
      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div v-html="data.standard_column_1"></div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div v-html="data.standard_column_2"></div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div v-html="data.standard_column_3"></div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="workflow-table__body">
          <div v-html="data.standard_column_4"></div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div v-html="data.standard_column_5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headers from './TableHeaders.vue';

export default {
  name: 'Standard',
  props: {
    data: Object
  },
  components: {
    Headers
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/partner-solutions/workflow.scss';
</style>
